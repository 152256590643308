import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material'; 
import { Link, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Link as MuiLink,
  Alert,
  Chip,
  Divider,
  Paper,
  Collapse,
  CardMedia,
  Button
} from '@mui/material';
import {
  CheckCircleOutline as CheckIcon
} from '@mui/icons-material';
import { getEstimateRequests } from '../services/api.js';
import { useTheme } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VDTitle from './VDTitle';


const MESSAGES = {
  ACCOUNT_CREATED: "Your account has been successfully created. Click Get an Estimate to get started",
  ESTIMATE_SUBMITTED: "Your estimate request has been submitted successfully. We'll notify you when it's ready",
  PROFILE_UPDATED: "Your profile has been updated successfully",
};

const StatusChip = ({ status }) => {
  const getStatusColor = (status) => {
    const statusColors = {
      'DRAFT': 'default',
      'SUBMITTED': 'primary',
      'PAID': 'success',
      'PROCESSING': 'warning',
      'COMPLETED': 'success'
    };
    return statusColors[status] || 'default';
  };

  return (
    <Chip
      label={status}
      size="small"
      color={getStatusColor(status)}
      sx={{ fontWeight: 'medium' }}
    />
  );
};

const IMG_URL = process.env.REACT_APP_API_URL;



const EstimateCard = ({ estimate }) => {



  const imageSrc = estimate.vrn_image_url ? `${IMG_URL}/estimates/file-content-binary/${estimate.vrn_image_url}` : '/motorshop.png';



  return (
    <Card>
      <CardMedia
        component="img"
        sx={{ height: 260, objectFit: 'cover' }}
        image={imageSrc}
        alt={`${estimate.vehicle_make} ${estimate.vehicle_model}`}
      />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>

          <Link
            to={estimate.status === 'DRAFT' ? '/request-estimate?draft=yes' : `/estimates/${estimate.id}`}
            style={{ textDecoration: 'none' }}
          >


            <Typography variant="h6" color="primary">
              {estimate.ref || `#${estimate.id}`} - {estimate.vehicle_registration_number}
            </Typography>

          </Link>


        </Box>

        <Box sx={{ mb: 1 }}>


          <StatusChip status={estimate.status} />

        </Box>

        <Typography variant="body1" color="text.secondary" gutterBottom>
          {estimate.vehicle_make} {estimate.vehicle_model} ({estimate.vehicle_year})
        </Typography>

        <Typography variant="body2" color="text.secondary" gutterBottom>
          {new Date(estimate.request_date).toLocaleDateString()}
        </Typography>

        {(estimate.total_cost && estimate.total_cost > 0) ? (
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Estimate Total: £{estimate.total_cost.toFixed(2)}
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            ----------
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};






const EnhancedAlert = ({ message, onClose }) => (
  <Collapse in={Boolean(message)}>
    <Paper
      elevation={2}
      sx={{
        mb: 2,
        overflow: 'hidden',
        borderRadius: 1,
        '& .MuiAlert-root': {
          borderRadius: 0,
          padding: 2,
        },
        '& .MuiAlert-icon': {
          fontSize: 28,
        },
        '& .MuiAlert-message': {
          fontSize: '1rem',
          py: 0.5,
        },
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: 3,
        }
      }}
    >
      <Alert
        icon={<CheckIcon fontSize="inherit" />}
        severity="success"
        onClose={onClose}
        sx={{
          alignItems: 'center',
          bgcolor: 'success.light',
          color: 'success.contrastText',
          '& .MuiAlert-icon': {
            color: 'success.contrastText',
          },
          '& .MuiIconButton-root': {
            color: 'success.contrastText',
            '&:hover': {
              bgcolor: 'success.dark',
            }
          }
        }}
      >
        {message}
      </Alert>
    </Paper>
  </Collapse>
);

const Dashboard = () => {
  const [estimates, setEstimates] = useState([]);
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  useEffect(() => {
    const messageCode = searchParams.get('message');
    if (messageCode && MESSAGES[messageCode]) {
      setMessage(MESSAGES[messageCode]);
    }
  }, [searchParams]);

  useEffect(() => {
    getEstimateRequests()
      .then(response => setEstimates(response.data.estimates))
      .catch(console.error);
  }, []);

  return (


<Container>
{message && (
      <EnhancedAlert
        message={message}
        onClose={() => setMessage('')}
      />
    )}


<Box sx={{ my: 2 }}>  {/* margin top and bottom */}
      <Button
        component={Link}
        to="/request-estimate"
        variant="outlined"
        startIcon={<AddCircleOutlineIcon />}
        size="large"
      >
        Get an Estimate
      </Button>
    </Box>



    <Grid container spacing={2}>
      {estimates.map((estimate) => (
        <Grid item xs={12} sm={12} md={4} key={estimate.id}>
          <EstimateCard estimate={estimate} />
        </Grid>
      ))}
    </Grid>


</Container>
  );
};

export default Dashboard;