import React, { useState, useEffect } from 'react';

import { 
  Box, 
  Typography, 
  Button, 
  Container, 
  Paper, 
  Stack,
  useTheme,
  useMediaQuery,
  Divider
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import BuildIcon from '@mui/icons-material/Build';
import AttachMoneyIcon from '@mui/icons-material/CurrencyPound';
import SpeedIcon from '@mui/icons-material/Speed';
import { ReactComponent as LogoNoIcon } from '../assets/logo-and-text.svg'; // Adjust the path as needed
import { CurrencyPound } from '@mui/icons-material';

const FeatureItem = ({ icon, title, description }) => (
  <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
    <Stack alignItems="center" spacing={2}>
      {icon}
      <Typography variant="h6" component="h3" align="center">
        {title}
      </Typography>
      <Typography variant="body2" align="center">
        {description}
      </Typography>
    </Stack>
  </Paper>
);

const HomePage = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 100); // Small delay to ensure the transition is visible

    return () => clearTimeout(timer);
  }, []);


  return (
    <>


      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          color: '#fff', // Changed to white for better contrast
          textAlign: 'center',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          marginLeft: 'calc(-50vw + 50%)', // Ensures full viewport width
          // Background image
          backgroundImage: `url('/bg/background.jpg')`,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
          },
          // Responsive background images using image-set
          backgroundImage: `
            image-set(
              url('/bg/background-mobile.jpg') 1x,
              url('/bg/background-mobile@2x.jpg') 2x,
              url('/bg/background-mobile@3x.jpg') 3x
            )
          `,
          [theme.breakpoints.up('sm')]: {
            backgroundImage: `
              image-set(
                url('/bg/background-tablet.jpg') 1x,
                url('/bg/background-tablet@2x.jpg') 2x,
                url('/bg/background-tablet@3x.jpg') 3x
              )
            `,
          },
          [theme.breakpoints.up('md')]: {
            backgroundImage: `
              image-set(
                url('/bg/background-desktop.jpg') 1x,
                url('/bg/background-desktop@2x.jpg') 2x,
                url('/bg/background-desktop@3x.jpg') 3x
              )
            `,
          },
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            position: 'relative',
            zIndex: 1,
            py: isMobile ? 4 : 8,
          }}
        >
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
              width: {
                xs: '240px',
                sm: '280px',
                md: '420px',
                xl: '480px'
              },
              height: 'auto',
              display: 'block',
              transition: 'opacity 5s ease-in-out', // Add transition for opacity
              opacity: opacity, // Use state to control opacity
            }
          }}
        >
<LogoNoIcon />
        </Box>

  
          <Typography 
            variant={isMobile ? 'h5' : 'h3'} 
            component="h1" 
            gutterBottom
            sx={{
              mt:2,
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', // Adds depth to text
            }}
          >
            Committed to Estimating Solutions
          </Typography>
          <Typography 
            variant={isMobile ? 'h6' : 'h4'} 
            component="h2" 
            gutterBottom
            sx={{
              fontWeight: 300,
              mb: 4,
            }}
          >
            Consistent, Accurate Vehicle Damage Assessments when you need them
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: 2,  // Adds spacing between buttons
              alignItems: 'center',
              justifyContent: 'center'  // Centers buttons horizontally
            }}
          >
            <Button 
              variant="contained" 
              color="primary" 
              size="large"
              component={RouterLink}
              to="/register"
            >
              Get Started
            </Button>
            <Button 
              variant="outlined" 
              color="primary" 
              size="large"
              component={RouterLink}
              to="/login"
            >
              Login
            </Button>
          </Box>

        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ my: 8 }}>
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          Why Choose VDAS?
        </Typography>
        <Typography variant="body1" align="center" paragraph>
        VDAS offers a comprehensive estimating solution for anyone in the accident repair industry. All our estimates are produced by qualified VDA’s to your specific pricing requirements. 
        </Typography>

        <Box sx={{ mt: 6 }}>
          <Stack 
            direction={{ xs: 'column', md: 'row' }} 
            spacing={4} 
            justifyContent="center"
          >
            <FeatureItem 
              icon={<BuildIcon sx={{ fontSize: 40, color: 'primary.main' }} />}
              title="Easy to Use"
              description="Simple interface to input vehicle details, upload damage photos, and submit estimate requests."
            />
            <FeatureItem 
              icon={<CurrencyPound sx={{ fontSize: 40, color: 'primary.main' }} />}
              title="Tailored Pricing"
              description="Set your labour rates and discounts to get transparent, accurate estimates."
            />
            <FeatureItem 
              icon={<SpeedIcon sx={{ fontSize: 40, color: 'primary.main' }} />}
              title="Quick Turnaround"
              description="Receive professional estimates fast, helping you serve your customers better."
            />
          </Stack>
        </Box>

        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Ready to simplify your repair estimation process?
          </Typography>
          <Button 
            variant="contained" 
            color="secondary"
            size="large"
            component={RouterLink}
            to="/register"
            sx={{ mt: 2 }}
          >
            Join VDAS Today
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
