import { createTheme } from '@mui/material/styles';



const theme = createTheme({
  // Base palette - keeping essential colors
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',  // Your brand color
    },
    secondary: {
      main: '#FF6B6B',
      contrastText: '#ffffff',
    },
    background: {
      default: '#000810',
      paper: '#051c2a',
      
    },
  },


  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        // Keeping SVG styles since you mentioned to keep them

      },
    },
    
    // Essential component customizations


    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
        },
      },
    },
  },
});

export default theme;