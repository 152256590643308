import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper,
  IconButton,
} from '@mui/material';
import { CloudUpload as UploadIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEstimateRequest } from './EstimateRequestContext';
import { verifyVRN, uploadImage, removeImage } from '../../../services/api';
import Grid from '@mui/material/Grid2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; 

const EstWizStep2ImageUpload = forwardRef((props, ref) => {
  const { state, dispatch } = useEstimateRequest();
  const [vrnStatus, setVrnStatus] = useState(null);
  const [isVrnProcessing, setIsVrnProcessing] = useState(false);
  const [isDamageProcessing, setIsDamageProcessing] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const isDamageImagesValid = state.damage_images && state.damage_images.length >= 3;

  // Expose validate method to the wizard
  useImperativeHandle(ref, () => ({
    validate: () => {
      const errors = {};

      if (!state.vrn_image_status || state.vrn_image_status !== 'VRN_MATCH') {
        errors.vrn_image_status = 'Please upload and verify the VRN image.';
      }

      // Only count images where contains_vrn is false
      const damageImages = state.damage_images.filter((img) => !img.contains_vrn);
      if (damageImages.length < 3) {
        errors.damage_images = 'Please upload at least 3 damage images.';
      }

      setValidationErrors(errors);
      const isValid = Object.keys(errors).length === 0;
      return { is_valid: isValid, errors };
    },
  }));

  // Handle clearing validation errors when user fixes them
  useEffect(() => {
    const damageImages = state.damage_images.filter((img) => !img.contains_vrn);
    if (damageImages.length >= 3 && validationErrors.damage_images) {
      // Clear the validation error
      setValidationErrors((prevErrors) => {
        const { damage_images, ...rest } = prevErrors;
        return rest;
      });
    }
  }, [state.damage_images, validationErrors.damage_images]);




  

  // Handle VRN image upload and verification
  const handleVrnImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setIsVrnProcessing(true);
    setVrnStatus(null);

    const formData = new FormData();
    formData.append('image', file);
    formData.append('vrn', state.vehicle_registration_number);

    try {
      // Call API to verify VRN
      const { statusMessage, vrnCheckResult, vrnFromImage } = await verifyVRN(formData);
      setVrnStatus(statusMessage);

      dispatch({ type: 'UPDATE_FIELD', field: 'vrn_image_status', value: vrnCheckResult });
      dispatch({ type: 'UPDATE_FIELD', field: 'vrn_from_image', value: vrnFromImage });

      if (vrnCheckResult === 'VRN_MATCH') {
        // Upload the image to the server
        const uploadFormData = new FormData();
        uploadFormData.append('file', file);
        uploadFormData.append('contains_vrn', 'true');
        uploadFormData.append('description', file.name);

        const uploadResult = await uploadImage(state.id, uploadFormData);

        // Set vrn_image in state directly
        dispatch({
          type: 'UPDATE_FIELD',
          field: 'vrn_image',
          value: {
            id: uploadResult.id,
            estimate_request_id: uploadResult.estimate_request_id,
            //image_url: uploadResult.image_url || uploadResult.url,
            image_url: file.name,
            description: file.name,
            contains_vrn: true,
          },
        });
      }
    } catch (error) {
      console.error('Error verifying VRN:', error);
      setVrnStatus('An error occurred while processing your request. Please try again.');
    } finally {
      setIsVrnProcessing(false);
    }
  };

  // Handle Damage Image Upload
  const handleDamageImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;
    setIsDamageProcessing(true);

    try {
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('contains_vrn', 'false');
          formData.append('description', file.name);

          const result = await uploadImage(state.id, formData);

          // Add the new image to the state
          dispatch({
            type: 'ADD_DAMAGE_IMAGE',
            payload: {
              id: result.id,
              estimate_request_id: result.estimate_request_id,
              //image_url: result.image_url || result.url,
              image_url: file.name,
              description: file.name,
              contains_vrn: false,
            },
          });
        })
      );
    } catch (error) {
      console.error('Error uploading damage images:', error);
      // Optionally display an error message to the user
    } finally {
      setIsDamageProcessing(false);
    }
  };

  // Handle Remove Damage Image
  const handleRemoveDamageImage = async (imageId) => {
    try {
      await removeImage(state.id, imageId);

      // Remove the image from the state
      dispatch({
        type: 'REMOVE_DAMAGE_IMAGE',
        payload: { id: imageId },
      });
    } catch (error) {
      console.error('Error removing image:', error);
      // Optionally display an error message to the user
    }
  };

  return (
    <Box>




      {/* VRN Image Upload Section */}
      <Paper elevation={3} style={{ padding: '16px', margin: '4px 0' }}>
        {state.vrn_image_status !== 'VRN_MATCH' ? (
          <>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="vrn-image-upload"
              type="file"
              onChange={handleVrnImageUpload}
              disabled={isVrnProcessing}
            />
            <label htmlFor="vrn-image-upload">
              <Button
                variant="outlined"
                component="span"
                disabled={isVrnProcessing}
                startIcon={isVrnProcessing ? <CircularProgress size={24} /> : <UploadIcon />}
              >
                {isVrnProcessing ? 'Uploading...' : 'Upload VRN Image'}
              </Button>
            </label>
            {vrnStatus ? (
              <Typography
                color={state.vrn_image_status === 'VRN_MATCH' ? 'primary' : 'error'}
                sx={{ mt: 1 }}
              >
                {vrnStatus}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ mt: 1 }}>
                You need to upload an image of the vehicle that contains the registration number
                (VRN) for verification.
              </Typography>
            )}
          </>
        ) : state.vrn_image ? (
          <>
            <Typography variant="body1" sx={{ mt: 1 }}>
              VRN verified: {state.vrn_image.description}
            </Typography>
          </>
        ) : (
          <Typography variant="body2" sx={{ mt: 1 }}>
            VRN verified, but image not found. Please contact support.
          </Typography>
        )}
        {validationErrors.vrn_image_status && (
          <Typography color="error" sx={{ mt: 1 }}>
            {validationErrors.vrn_image_status}
          </Typography>
        )}
      </Paper>

      {/* Damage Images Upload Section */}
      <Paper elevation={3} style={{ padding: '16px', margin: '16px 0' }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="damage-images-upload"
          type="file"
          multiple
          onChange={handleDamageImageUpload}
          disabled={isDamageProcessing}
        />
        <label htmlFor="damage-images-upload">
          <Button
            variant="outlined"
            component="span"
            disabled={isDamageProcessing}
            startIcon={isDamageProcessing ? <CircularProgress size={24} /> : <UploadIcon />}
          >
            {isDamageProcessing ? 'Uploading...' : 'Upload Damage Images'}
          </Button>
        </label>

        <Typography variant="body2" sx={{ mt: 1 }}>
          Please upload at least 3 clear images of the damage.
          {isDamageImagesValid && <CheckCircleIcon color="primary" sx={{ ml: 1 }} />}
        </Typography>


        {state.damage_images.filter((image) => !image.contains_vrn).length === 0 ? (
          <Typography variant="body2" sx={{ mt: 1 }}>
 
          </Typography>
        ) : (
          state.damage_images
            .filter((image) => !image.contains_vrn)
            .map((image) => (
              <Box
                key={image.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  borderBottom: '1px solid #e0e0e0',
                  pb: 1,
                }}
              >
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {image.description}
                </Typography>
                <IconButton
                  onClick={() => handleRemoveDamageImage(image.id)}
                  size="small"
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))
        )}

        {/* Validation Error */}
        {validationErrors.damage_images && (
          <Typography color="error" sx={{ mt: 1 }}>
            {validationErrors.damage_images}
          </Typography>
        )}
      </Paper>
    </Box>
  );
});

export default EstWizStep2ImageUpload;