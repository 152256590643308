import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card, 
  CardContent,
  Typography,
  Chip,
  Stack,
  CircularProgress,
  Container,
  Link,
  Divider,
  Alert,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getEstimate, testProcessingEstimate } from '../services/api';

const IMG_URL = process.env.REACT_APP_API_URL;

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(amount || 0);
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const StatusChip = ({ status }) => {
  const getStatusColor = (status) => {
    const statusColors = {
      'DRAFT': 'default',
      'SUBMITTED': 'primary',
      'PAID': 'success',
      'PROCESSING': 'warning',
      'COMPLETED': 'success'
    };
    return statusColors[status] || 'default';
  };

  return (
    <Chip
      label={status}
      color={getStatusColor(status)}
      sx={{ fontWeight: 'medium' }}
    />
  );
};

const EstimateDetail = () => {
  const { estimateId } = useParams();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const [formData, setFormData] = useState({
    labour_hours: '',
    labour_cost: '',
    paint_hours: '',
    paint_cost: '',
    specialist_hours: '',
    specialist_cost: '',
    parts_cost: '',
    total_cost: '',
    estimator_reference: ''
  });

  useEffect(() => {
    const fetchEstimate = async () => {
      try {
        const data = await getEstimate(estimateId);
        setEstimate(data);
      } catch (err) {
        setError(err.message || 'Failed to fetch estimate details');
      } finally {
        setLoading(false);
      }
    };

    fetchEstimate();
  }, [estimateId]);

  const resetForm = () => { 
    setFormData({ 
      labour_hours: '', 
      labour_cost: '', 
      paint_hours: '', 
      paint_cost: '', 
      specialist_hours: '', 
      specialist_cost: '', 
      parts_cost: '',
      total_cost: '', 
      estimator_reference: '' 
    }); 
  }; 

  const handleCloseModal = () => {
    setIsTestModalOpen(false);
    resetForm();
    setSubmitError(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setSubmitError(null);
    
    try {
      await testProcessingEstimate(estimateId, formData);
      handleCloseModal();
      // Refresh the estimate data
      window.location.reload();
    } catch (error) {
      setSubmitError(error.response?.data?.message || 'Failed to process estimate');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!estimate) {
    return (
      <Alert severity="info" sx={{ mt: 2 }}>
        Estimate not found
      </Alert>
    );
  }

  return (
    <Box>
      <Container maxWidth="lg" sx={{ py: 2 }}>
        <Stack spacing={3}>
          {/* Back Navigation */}
          <Link
            component={RouterLink}
            to="/dashboard"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'primary.main',
              '&:hover': { color: 'primary.dark' }
            }}
          >
            <ChevronLeftIcon sx={{ mr: 1 }} />
            Back to Dashboard
          </Link>

          {/* Header Section */}

          <Grid container spacing={2}>
            <Grid size={12}>
              <Grid container spacing={2}>
                <Grid size={{xs: 12, md: 6}}>
                  <Card>
                    <CardContent>

                        <Stack spacing={1}>
                          <Typography variant="h5" color="text.secondary">
                            Estimate {estimate.ref || `#${estimate.id}`}
                          </Typography>
                          <Typography
                            variant="h6"
                            color="warning.main"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {estimate.vehicle_registration_number}
                          </Typography>
                          <Typography color="text.secondary">
                            Requested on {formatDate(estimate.request_date)}
                          </Typography>
                          <Typography color="text.secondary">Platform is {estimate.estimate_platform}</Typography>
                        </Stack>
     

                      <Box sx={{ mt: 2 }} >
                        <StatusChip status={estimate.status} /> &nbsp;
                        {estimate.status === 'PROCESSING' && (
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => setIsTestModalOpen(true)}
                          >
                            Test Processing
                          </Button>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid size={{xs: 12, md: 6}}>
              {/* Vehicle Information */}
                  <Card>
                    <CardContent>
                      <Typography variant="h5"  color="text.secondary" gutterBottom>
                        Vehicle
                      </Typography>
                      <Box
                        display="grid"
                        gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}
                        gap={3}
                      >
                        <Stack spacing={2}>
                          <Box>
                            <Typography color="text.secondary">Registration Number</Typography>
                            <Typography variant="body1">
                              {estimate.vehicle_registration_number}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography color="text.secondary">Make & Model</Typography>
                            <Typography variant="body1">
                              {estimate.vehicle_make} {estimate.vehicle_model}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack spacing={2}>
                          <Box>
                            <Typography color="text.secondary">Year</Typography>
                            <Typography variant="body1">{estimate.vehicle_year}</Typography>
                          </Box>
                          <Box>
                            <Typography color="text.secondary">Color</Typography>
                            <Typography variant="body1">{estimate.vehicle_color}</Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

              </Grid>
            </Grid>     
          </Grid>

          <Paper sx={{ p: 2 }}>
      <Typography variant="h5"  color="text.secondary" gutterBottom>
        Estimate of Costs
      </Typography>

      {estimate.pdf_url && (
        <Box sx={{ mb: 1, mt:2 }}>
        <Link
          href={`${IMG_URL}/estimates/file-content-binary/${estimate.pdf_url}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            mb: 3
          }}
        >
          <PictureAsPdfIcon sx={{ mr: 1 }} />
          Download Estimate PDF
        </Link>
        </Box>
      )}

      {estimate.status === 'COMPLETED' ? (
        <Paper
  elevation={1}
  sx={{
    p: 2,
    border: 1,
    borderStyle: 'dashed',
    borderColor: 'grey.500',
  
  }}
>
          {/* Header Row */}
          <Grid container columns={3} sx={{ mb: 2 }}>
            <Grid size={1}>
              <Typography color="text.secondary" fontWeight="medium">Description</Typography>
            </Grid>
            <Grid size={1}>
              <Typography color="text.secondary" fontWeight="medium" align="right">Hours</Typography>
            </Grid>
            <Grid size={1}>
              <Typography color="text.secondary" fontWeight="medium" align="right">Cost</Typography>
            </Grid>
          </Grid>

          {/* Labour Row */}
          <Grid container columns={3} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
            <Grid size={1}>
              <Typography>Labour</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">{estimate.labour_hours}</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">{formatCurrency(estimate.labour_cost)}</Typography>
            </Grid>
          </Grid>

          {/* Paint Row */}
          <Grid container columns={3} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
            <Grid size={1}>
              <Typography>Paint</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">{estimate.paint_hours}</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">{formatCurrency(estimate.paint_cost)}</Typography>
            </Grid>
          </Grid>

          {/* Specialist Row */}
          <Grid container columns={3} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
            <Grid size={1}>
              <Typography>Specialist</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">{estimate.specialist_hours}</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">{formatCurrency(estimate.specialist_cost)}</Typography>
            </Grid>
          </Grid>

          {/* Parts Row */}
          <Grid container columns={3} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
            <Grid size={1}>
              <Typography>Parts</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">-</Typography>
            </Grid>
            <Grid size={1}>
              <Typography align="right">{formatCurrency(estimate.parts_cost)}</Typography>
            </Grid>
          </Grid>

          {/* Total Row */}
          <Grid container columns={3} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
            <Grid size={1}>
              <Typography fontWeight="medium">Total Cost</Typography>
            </Grid>
            <Grid size={1} />
            <Grid size={1}>
              <Typography align="right" fontWeight="medium">
                {formatCurrency(estimate.total_cost)}
              </Typography>
            </Grid>
          </Grid>

          {/* Reference Row */}
          <Grid container columns={3} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
            <Grid size={1}>
              <Typography>Estimator Reference</Typography>
            </Grid>
            <Grid size={2}>
              <Typography align="right">{estimate.estimator_reference}</Typography>
            </Grid>
          </Grid>

          {/* Date Row */}
          <Grid container columns={3} sx={{ py: 2 }}>
            <Grid size={1}>
              <Typography>Estimate Date</Typography>
            </Grid>
            <Grid size={2}>
              <Typography align="right">
                {new Intl.DateTimeFormat('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                }).format(new Date(estimate.estimate_date))}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Typography color="text.secondary" sx={{ mt: 2 }}>
          This estimate is currently being processed; check back later for updates.
        </Typography>
      )}
    </Paper>
          {/* Damage Information */}
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom  color="text.secondary">
                Damage
              </Typography>
              <Stack spacing={3}>
                <Box>
                  <Typography variant="body1">
                    {estimate.damage_description || 'No description provided'}
                  </Typography>
                </Box>

                <Box>
                  <Typography color="text.secondary">Affected Areas</Typography>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                    sx={{ mt: 2 }}
                  >
                    {estimate.affected_areas && Object.entries(estimate.affected_areas).map(([area, isAffected]) =>
                      isAffected && (
                        <Chip
                          key={area}
                          label={area.replace('_', ' ')}
                          color="primary"
                          variant="outlined"
                          size="small"
                        />
                      )
                    )}
                  </Box>
                </Box>

                <Box>
                  <Box
                    display="grid"
                    gridTemplateColumns={{
                      xs: '1fr',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(3, 1fr)'
                    }}
                    gap={2}
                  >
                    {estimate.damage_images?.map((image) => (
                      <Box key={image.id} position="relative">
                        <Box
                          component="img"
                          src={image.image_url ? `${IMG_URL}/estimates/file-content-binary/${image.image_url}` : '/placeholder-image.png'}
                          alt="Damage"
                          sx={{
                            width: '100%',
                            height: 200,
                            objectFit: 'cover',
                            borderRadius: 1,
                          }}
                        />
                        {image.contains_vrn && (
                          <Chip
                            label="Contains VRN"
                            color="warning"
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: theme.spacing(1),
                              right: theme.spacing(1)
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>

      <Dialog 
        open={isTestModalOpen} 
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Test Processing - {estimate.ref || `#${estimate.id}`}</DialogTitle>
        <DialogContent>
          {submitError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <Box sx={{ pt: 2 }}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Labour Hours"
                  name="labour_hours"
                  type="number"
                  value={formData.labour_hours}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.5" }}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Labour Cost"
                  name="labour_cost"
                  type="number"
                  value={formData.labour_cost}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.01" }}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Paint Hours"
                  name="paint_hours"
                  type="number"
                  value={formData.paint_hours}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.5" }}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Paint Cost"
                  name="paint_cost"
                  type="number"
                  value={formData.paint_cost}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.01" }}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Specialist Hours"
                  name="specialist_hours"
                  type="number"
                  value={formData.specialist_hours}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.5" }}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Specialist Cost"
                  name="specialist_cost"
                  type="number"
                  value={formData.specialist_cost}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.01" }}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Parts Cost"
                  name="parts_cost"
                  value={formData.parts_cost}
                  onChange={handleInputChange}
                  disabled={submitting}
                />
              </Grid>          
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Total Cost"
                  name="total_cost"
                  type="number"
                  value={formData.total_cost}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.01" }}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Estimator Reference"
                  name="estimator_reference"
                  value={formData.estimator_reference}
                  onChange={handleInputChange}
                  disabled={submitting}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseModal}
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Processing...
              </>
            ) : 'Submit Test'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EstimateDetail;