import axiosInstance, { tokenManager } from './axiosConfig';

class AuthService {
  async login(email, password, rememberMe = false) {
    try {
        const response = await axiosInstance.post('/auth/login', {
            email,
            password,
            remember_me: rememberMe
        });
        
        // Keep your existing logging for debugging
        console.log('Full response data:', JSON.stringify(response.data, null, 2));
        console.table(response.data);
        
        if (response.data.token) {
            tokenManager.setUser(response.data);
        }
        return response.data;
    } catch (error) {
        // Extract the exact error message from the server response
        const errorMessage = error.response?.data?.error || 'Login failed';
        console.error('Login error:', errorMessage);
        // Throw a new error with the exact server message
        throw new Error(errorMessage);
    }
}

      isAuthenticated() {
        return !!tokenManager.getToken();
      }

      async logout() {
        try {
          await axiosInstance.post('/auth/logout');
        } catch (error) {
          console.error('Logout error:', error);
        } finally {
          tokenManager.removeUser();
        }
      }

  async register(userData) {
    try {
      const formData = new FormData();
      
      Object.keys(userData).forEach(key => {
        if (key === 'default_retail_menu_file' && userData[key] instanceof File) {
          formData.append(key, userData[key]);
        } else {
          formData.append(key, userData[key]);
        }
      });

      const response = await axiosInstance.post('/auth/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (response.data.token) {
            tokenManager.setUser(response.data);
      }
      return response.data;
    } catch (error) {
      console.error('Registration error:', error.response?.data?.error || error.message);
      throw error;
    }
  }

  getCurrentUser() {
    return tokenManager.getUser();
  }

  async updateProfile(userData, file) {
    try {
      const formData = new FormData();
      
      // Add all profile data except the file
      Object.keys(userData).forEach(key => {
        formData.append(key, userData[key]);
      });
  
      // Add file if present with new field name
      if (file) {
        formData.append('new_retail_menu_file', file);
        console.log('File appended:', file.name); // Debug log
      }
  
      const response = await axiosInstance.put('/auth/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'

        }
      });
  
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const updatedUser = {
          ...currentUser,
          user_profile: response.data.user_profile
        };
        tokenManager.setUser(updatedUser);
      }
  
      return response.data;
    } catch (error) {
      console.error('Update profile error:', error.response?.data?.error || error.message);
      throw error;
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
          return await axiosInstance.post('/auth/change-password', {
        oldPassword,
        newPassword
      });
    } catch (error) {
      console.error('Change password error:', error.response?.data?.error || error.message);
      throw error;
    }
  }

  async forgotPassword(email) {
    try {
          return await axiosInstance.post('/auth/forgot-password', { email });
    } catch (error) {
      console.error('Forgot password error:', error.response?.data?.error || error.message);
      throw error;
    }
  }

  async resetPassword(token, newPassword) {
    try {
          return await axiosInstance.post(`/auth/reset-password/${token}`, {
        new_password: newPassword
      });
    } catch (error) {
      console.error('Reset password error:', error.response?.data?.error || error.message);
      throw error;
    }
  }

  updateStoredUser(userData) {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const updatedUser = {
          ...currentUser,
          user_profile: { ...currentUser.user_profile, ...userData }
        };
            tokenManager.setUser(updatedUser);
      }
    } catch (error) {
      console.error('Error updating stored user:', error);
    }
  }
}

// Create instance
const authService = new AuthService();

// Export instance
export default authService;