// AboutPage.js
import React from 'react';
import { Box, Card, CardContent, Typography, Container, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Grid from '@mui/material/Grid2';  // Using Grid2
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PaymentIcon from '@mui/icons-material/Payment';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SpeedIcon from '@mui/icons-material/Speed';
import BuildIcon from '@mui/icons-material/Build';
import VDTitle from './VDTitle';

const AboutPage = () => {
  return (
    <Container maxWidth="md">

<VDTitle>About VDAS</VDTitle>
      <Box sx={{ mt: 2, mb: 6 }}>

        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          VDAS (Vehicle Damage Assessment Solutions) is a cutting-edge provider of vehicle damage assessments. We use our own unique platform that is designed to streamline the repair estimation process.
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Feature List Card */}
          <Card>
            <CardContent>
            <VDTitle>Features</VDTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>

              </Box>
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Submit requests for Accident Repair Estimates" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Monitor and manage your estimate list" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Choose from 'Retail' or user defined rates" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Choose your preferred estimating system" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Allow networking of the completed estimate" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Process payments seamlessly" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    sx={{
                      width: '90%',
                      height: 0,
                      paddingTop: '75%', // 4:3 aspect ratio
                      position: 'relative',
                      '& img': {
                        position: 'absolute',
                        top: 20,
                        left: 30,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 3
                      }
                    }}
                  >
                    <img
                      src="/bg/background-tablet.jpg"
                      alt="VDAS Background"
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Mission Statement Card */}
          <Card>
            <CardContent>


                <VDTitle>Mission Statement</VDTitle>

              <Typography variant="body1">
                Our goal is to offer an accurate, consistent and easy estimating service to anyone. All we need is a few vehicle details and images to enable us to compile an estimate. VDAS provides a tool that saves time, increases accuracy, and improves customer service. With VDAS, you can focus on what you do best - fixing vehicles - while we handle the complexities of damage assessment.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutPage;