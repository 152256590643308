import React, { useState } from 'react';
import { Box, TextField, IconButton, Snackbar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

const BasicInfoForm = ({ formData, onChange, errors }) => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ [name]: value });
  };

  const handlePostcodeLookup = async () => {
    if (!formData.postcode?.trim()) {
      setSnackbarMessage('Please enter a postcode');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`https://api.postcodes.io/postcodes/${formData.postcode}`);
      const data = await response.json();

      if (data.status === 200 && data.result) {
        const updates = {
          postcode: data.result.postcode,
          city: data.result.admin_district || '',
          county: data.result.region || '',
        };
        onChange(updates);
      } else {
        setSnackbarMessage('Postcode not found. Please check and try again');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error looking up postcode. Please try again');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container spacing={1}>
        {/* Personal Information */}
        <Grid size={{ xs: 12, md: 6 }} >
          <TextField
            margin="normal"
            required
            fullWidth
            id="full_name"
            label="Full Name"
            name="full_name"
            autoComplete="name"
            value={formData.full_name || ''}
            onChange={handleChange}
            error={!!errors?.full_name}
            helperText={errors?.full_name}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email || ''}
            onChange={handleChange}
            error={!!errors?.email}
            helperText={errors?.email}
          />
        </Grid>

        {/* Company Information */}
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            fullWidth
            id="entity_name"
            label="Company Name"
            name="entity_name"
            autoComplete="organization"
            value={formData.entity_name || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone_number"
            label="Phone Number"
            name="phone_number"
            autoComplete="tel"
            value={formData.phone_number || ''}
            onChange={handleChange}
            error={!!errors?.phone_number}
            helperText={errors?.phone_number}
          />
        </Grid>

        {/* Address Information */}
        <Grid size={{ xs: 12, md: 6 }}>
          <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="postcode"
              label="Postcode"
              name="postcode"
              autoComplete="postal-code"
              value={formData.postcode || ''}
              onChange={handleChange}
              error={!!errors?.postcode}
              helperText={errors?.postcode}
            />
            <IconButton 
              onClick={handlePostcodeLookup}
              sx={{ mt: 2.5 }}
              color="primary"
              aria-label="lookup postcode"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : <SearchIcon />}
            </IconButton>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="street"
            label="Street Address"
            name="street"
            autoComplete="street-address"
            value={formData.street || ''}
            onChange={handleChange}
            error={!!errors?.street}
            helperText={errors?.street}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            fullWidth
            id="city"
            label="City"
            name="city"
            autoComplete="address-level2"
            value={formData.city || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            fullWidth
            id="county"
            label="County"
            name="county"
            autoComplete="address-level1"
            value={formData.county || ''}
            onChange={handleChange}
          />
        </Grid>

        {/* Password Fields */}
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="new-password"
            value={formData.password || ''}
            onChange={handleChange}
            error={!!errors?.password}
            helperText={errors?.password}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            autoComplete="new-password"
            value={formData.confirmPassword || ''}
            onChange={handleChange}
            error={!!errors?.confirmPassword}
            helperText={errors?.confirmPassword}
          />
        </Grid>
      </Grid>

      <Snackbar
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={() => setSnackbarOpen(false)}
  message={snackbarMessage}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
/>
    </Box>
  );
};

export default BasicInfoForm;