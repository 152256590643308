import React from 'react';
import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorAlert = ({ error, onDismiss }) => {
  if (!error) return null;

  let errorMessage = error;
  
  // Handle specific error cases
  if (error.includes('409')) {
    errorMessage = 'An account with this email already exists. Please try logging in or use a different email address.';
  } else if (error.includes('400')) {
    errorMessage = 'Please check your information and try again.';
  } else if (error.includes('500')) {
    errorMessage = 'Something went wrong on our end. Please try again later.';
  }

  return (
    <Alert 
      severity="error"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onDismiss}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={{ mb: 2 }}
    >
      {errorMessage}
    </Alert>
  );
};

export default ErrorAlert;