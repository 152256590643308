

// api.js


import axiosInstance from './axiosConfig';


// Create a reusable Axios instance



// New function to get the existing draft estimate
export const getDraftEstimate = async () => {
  try {
    const response = await axiosInstance.get('/estimates/draft');
    return response.data;
  } catch (error) {
    console.error('Error fetching draft estimate:', error);
    throw error;
  }
};



// New function to delete the existing draft estimate
export const deleteDraftEstimate = async () => {
  try {
    const response = await axiosInstance.delete('/estimates/draft');
    return response.data;
  } catch (error) {
    console.error('Error deleting draft estimate:', error);
    throw error;
  }
};

export const saveDraftEstimateRequest = async (estimateData) => {
  try {
    const response = await axiosInstance.post('/estimates/draft', estimateData);
    return response; // Return the full response to access status code
  } catch (error) {
    console.error('Error saving draft estimate request:', error);
    throw error;
  }
};

export const createPaymentIntent = async (estimateId) => {
  try {
    console.log('Sending request to create payment intent for estimate ID:', estimateId);
    const response = await axiosInstance.post(`/estimates/${estimateId}/payment-intent`);
    console.log('Received response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in createPaymentIntent:', error.response?.data || error.message);
    throw error;
  }
};

/*
export const confirmPayment = async (estimateRequestId, paymentIntentId) => {
  try {
    const response = await axiosInstance.post(`/estimates/${estimateRequestId}/confirm-payment`, { paymentIntentId });
    return response.data;
  } catch (error) {
    throw error;
  }
};
*/

export const lookupVehicle = async (vrn) => {
  try {
    const response = await axiosInstance.post('/vehicles/lookup', { vrn });
    return response;
  } catch (error) {
    console.error('Error looking up vehicle:', error);
    throw error;
  }
};

// New function for verifying VRN

export const verifyVRN = async (formData) => {

  try {
    const response = await axiosInstance.post('/vehicles/verify-vrn', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    const result = response.data;
    let statusMessage = '';

    switch (result.vrnCheckResult) {
      case 'VRN_MATCH':
        statusMessage = `VRN verification success for ${result.vrn}.`;
        break;
      case 'VRN_NOT_MATCH':
        statusMessage = `The registration number in the image (${result.vrn}) doesn't match the one you provided. Please check and try again.`;
        break;
      case 'NO_VRN':
        statusMessage = "We couldn't detect a registration number in the image. Please upload a clearer image.";
        break;
      case 'ERROR':
        statusMessage = `An error occurred: ${result.error}. Please try again.`;
        break;
      default:
        statusMessage = 'An unexpected error occurred. Please try again.';
    }

    return {
      statusMessage,
      vrnCheckResult: result.vrnCheckResult,
      vrnFromImage: result.vrn
    };
  } catch (error) {
    console.error('Error verifying VRN:', error);
    throw error;
  }
};

// New function for processing payments
export const processPayment = async (totalAmount, paymentDetails) => {
  try {
    const response = await axiosInstance.post('/payments/process', {
      amount: totalAmount,
      ...paymentDetails
    });
    return response.data;
  } catch (error) {
    console.error('Error processing payment:', error);
    throw error;
  }
};

export const getEstimateRequests = async () => {
  try {
    console.log('Fetching estimate requests...');
    const response = await axiosInstance.get('/estimates/');
    console.log('Estimate requests response:', response);
    return response;
  } catch (error) {
    console.error('Error fetching estimate requests:', error);
    throw error;
  }
};



export const submitEstimate = async (estimateId) => {
  try {
    const response = await axiosInstance.post(`/estimates/${estimateId}/submit`);
    return response.data;
  } catch (error) {
    console.error('Error submitting estimate:', error);
    throw error;
  }
};

export const getEstimate = async (estimateId) => {
  try {
    const response = await axiosInstance.get(`/estimates/${estimateId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching estimate:', error);
    throw error;
  }
};

export const deleteEstimate = async (estimateId) => {
  try {
    const response = await axiosInstance.delete(`/estimates/${estimateId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting estimate:', error);
    throw error;
  }
};

export const testProcessingEstimate = async (estimateId, formData) => {
  try {
    // Step 1: Generate fake PDF
    const pdfResponse = await axiosInstance.post(
      `/estimates/${estimateId}/generate-fake-pdf`,
      formData,
      {
        responseType: 'blob' // Still need this to handle the binary response correctly
      }
    );
    
    const pdfBlob = pdfResponse.data;

    // Step 2: Create FormData for the complete endpoint
    const completeFormData = new FormData();
    
    // Add the PDF blob as a file
    completeFormData.append('estimate_file', pdfBlob, 'estimate.pdf');
    
    // Add all the form fields
    completeFormData.append('estimate_date', new Date().toISOString());
    completeFormData.append('labour_hours', formData.labour_hours);
    completeFormData.append('labour_cost', formData.labour_cost);
    completeFormData.append('paint_hours', formData.paint_hours);
    completeFormData.append('paint_cost', formData.paint_cost);
    completeFormData.append('specialist_hours', formData.specialist_hours);
    completeFormData.append('specialist_cost', formData.specialist_cost);
    completeFormData.append('parts_cost', formData.parts_cost);
    completeFormData.append('total_cost', formData.total_cost);
    
    if (formData.estimator_reference) {
      completeFormData.append('estimator_reference', formData.estimator_reference);
    }

    // Make the complete estimate call
    const completeResponse = await axiosInstance.post(
      `/estimates/${estimateId}/complete`,
      completeFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return completeResponse.data;
  } catch (error) {
    console.error('Error in test processing:', error);
    throw error;
  }
};

export const uploadImage = async (estimateId, formData) => {
  try {
    const response = await axiosInstance.post(`/estimates/${estimateId}/upload-image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const removeImage = async (estimateId, imageId) => {
  try {
    const response = await axiosInstance.delete(`/estimates/${estimateId}/remove-image/${imageId}`);
    return response.data;
  } catch (error) {
    console.error('Error removing image:', error);
    throw error;
  }
};







/*
export const register = async (userData) => {
  try {
    const response = await axiosInstance.post('/auth/register', userData);
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const login = async (credentials) => {
  try {
    const response = await axiosInstance.post('/auth/login', credentials);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await axiosInstance.post('/auth/logout');
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const response = await axiosInstance.get('/auth/profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};


export const updateProfile = async (profileData) => {
  try {
    const response = await axiosInstance.put('/auth/profile', profileData);
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

export const changePassword = async (passwordData) => {
  try {
    const response = await axiosInstance.post('/auth/change-password', passwordData);
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await axiosInstance.post('/auth/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error('Error requesting password reset:', error);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axiosInstance.post(`/auth/reset-password/${token}`, { newPassword });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};
*/

// todo 
// New function to upload a retail menu file


export const uploadRetailMenuFile = async (estimateId, formData) => {
  try {
    const response = await axiosInstance.post(`/estimates/${estimateId}/upload-retail-menu`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading retail menu file:', error);
    throw error;
  }
};

// New function to remove the retail menu file
export const removeRetailMenuFile = async (estimateId) => {
  try {
    const response = await axiosInstance.delete(`/estimates/${estimateId}/remove-retail-menu`);
    return response.data;
  } catch (error) {
    console.error('Error removing retail menu file:', error);
    throw error;
  }
};

export const getFileContent = async (filename) => {
  try {
   // const response = await axiosInstance.get(`/estimates/file-content/${encodeURIComponent(filename)}`, {
    const response = await axiosInstance.get(`estimates/file-content/${filename}`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching file:', error);
    throw error;
  }
};



// Add more API functions as needed

export default axiosInstance;