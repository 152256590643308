// src/components/wizards/estimateRequest/EstWizStep4Review.js

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Chip,
  Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEstimateRequest } from './EstimateRequestContext';
import { submitEstimate } from '../../../services/api';

const EstWizStep4Review = forwardRef((props, ref) => {
  const { state, dispatch } = useEstimateRequest();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  const affectedAreas = state.affected_areas || {};
  const damageImages = state.damage_images || [];

  // Fixed price for the estimate (you may want to calculate this based on your business logic)
 // const estimatePrice = 49.99; // Example price in GBP
 // const vatRate = 0.20; // 20% VAT
 // const vatAmount = estimatePrice * vatRate;
 // const totalPrice = estimatePrice + vatAmount;

  const estimatePrice = state.cost_net;
  const vatRate = 0.20;
  //const vatAmount = estimatePrice * vatRate;
  const vatAmount = state.cost_vat;
  const totalPrice = state.cost_gross;

  // Calculate delivery date (today + 2 days)
  const deliveryDate = new Date();
  deliveryDate.setDate(deliveryDate.getDate() + 2);
  const formattedDeliveryDate = deliveryDate.toLocaleDateString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Expose validate method to the wizard
  useImperativeHandle(ref, () => ({
    validate: async () => {
      setIsSubmitting(true);
      setSubmissionError(null);
      try {
        // Call API to submit the estimate and create payment intent
        const result = await submitEstimate(state.id);

        
        dispatch({ type: 'UPDATE_FIELD', field: 'status', value: 'SUBMITTED' });
        dispatch({ type: 'UPDATE_FIELD', field: 'payment_intent', value: result.payment_intent });


        setIsSubmitting(false);
        return { is_valid: true };
      } catch (error) {
        console.error('Error submitting estimate:', error);
        setIsSubmitting(false);
        setSubmissionError('An error occurred while submitting your estimate. Please try again.');
        return { is_valid: false, errors: { submission: 'Submission failed' } };
      }
    },
  }));

  return (
    <Box>
      {submissionError && (
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          {submissionError}
        </Typography>
      )}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="estimate-cost-content"
          id="estimate-cost-header"
        >
          <Typography variant="h6">Estimate Cost</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <ListItemText
                primary="Estimate Fee"
                secondary={`£${estimatePrice.toFixed(2)}`}
                secondaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`VAT (${(vatRate * 100).toFixed(0)}%)`}
                secondary={`£${vatAmount.toFixed(2)}`}
                secondaryTypographyProps={{ variant: 'subtitle1' }}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Total"
                secondary={`£${totalPrice.toFixed(2)}`}
                primaryTypographyProps={{ variant: 'h6' }}
                secondaryTypographyProps={{ variant: 'h6', color: 'primary' }}
              />
            </ListItem>
          </List>
          <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
            Delivery: Your estimate will be emailed to you by {formattedDeliveryDate}.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="vehicle-summary-content"
          id="vehicle-summary-header"
        >
          <Typography variant="h6">Vehicle Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <ListItemText
                primary="Registration Number"
                secondary={state.vehicle_registration_number || 'N/A'}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Make" secondary={state.vehicle_make || 'N/A'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Model" secondary={state.vehicle_model || 'N/A'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Year" secondary={state.vehicle_year || 'N/A'} />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="damage-summary-content"
          id="damage-summary-header"
        >
          <Typography variant="h6">Damage Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>Affected Areas:</Typography>
          <Stack direction="row" spacing={1} sx={{ mb: 2, flexWrap: 'wrap', gap: 1 }}>
            {Object.entries(affectedAreas).map(
              ([area, isAffected]) =>
                isAffected && (
                  <Chip
                    key={area}
                    label={area.replace(/-/g, ' ')}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )
            )}
          </Stack>
          {state.damage_description && (
            <>
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
                Damage Description:
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {state.damage_description}
              </Typography>
            </>
          )}
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
            Uploaded Images:
          </Typography>
          <List dense>
            {damageImages
              .filter((image) => !image.contains_vrn)
              .map((image, index) => (
                <ListItem key={image.id}>
                  <ListItemText
                    primary={`Image ${index + 1}: ${image.description || 'No description'}`}
                  />
                </ListItem>
              ))}
          </List>
        </AccordionDetails>
      </Accordion>

      {/* Display a submit button with loading state if desired */}
      {isSubmitting && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
});

export default EstWizStep4Review;