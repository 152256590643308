
// src/components/wizards/estimateRequest/EstimateRequestWizard.js

import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEstimateRequest } from './EstimateRequestContext';
import { useAuth } from '../../../contexts/AuthContext';

import {
  getDraftEstimate,
  saveDraftEstimateRequest,
  deleteDraftEstimate,
} from '../../../services/api';

import EstWizStep1VehicleInfoRates from './EstWizStep1VehicleInfoRates';
import EstWizStep2ImageUpload from './EstWizStep2ImageUpload';
import EstWizStep3DamageAreaSelector from './EstWizStep3DamageAreaSelector';
import EstWizStep4Review from './EstWizStep4Review';
import EstWizStep5Payment from './EstWizStep5Payment';


const steps = ['Vehicle', 'Images', 'Damage', 'Review', 'Payment'];

const EstimateRequestWizard = () => {
  const { state, dispatch } = useEstimateRequest();
  const stepRefs = useRef([]);
  const navigate = useNavigate();
  const { user} = useAuth();

  // UI States
  const [showDraftDialog, setShowDraftDialog] = useState(false);
  const [existingDraft, setExistingDraft] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [savingDraft, setSavingDraft] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    const initializeWizard = async () => {
      try {
        // Fetch user profile
        const profileData = user.user_profile;
        dispatch({
          type: 'SET_PROFILE_DATA',
          payload: {
            default_labour_rate: profileData.default_labour_rate,
            default_parts_discount: profileData.default_parts_discount,
            default_paint_discount: profileData.default_paint_discount,
            gt_global_account_id: profileData.gt_global_account_id,
            audatex_account_id: profileData.audatex_account_id,
            default_retail_menu_file: profileData.default_retail_menu_file,
          },
        });

        // Check for existing draft
        const draftData = await getDraftEstimate();


        if (draftData && draftData.id) {
        // Only proceed if VRN_MATCH status exists
        if (draftData.vrn_image_status === 'VRN_MATCH') {
          
          // Check if damage_images exists and is not empty
          if (!draftData.damage_images || !Array.isArray(draftData.damage_images)) {
            console.error('Draft has VRN_MATCH status but damage_images array is missing or invalid', {
              draftId: draftData.id,
              status: draftData.vrn_image_status
            });
          } else {
            // Find the first damage image that contains VRN
            const vrnImage = draftData.damage_images.find(image => image.contains_vrn === true);
            
            if (vrnImage) {
              // Update the vrn_image property with the required fields
              draftData.vrn_image = {
                id: vrnImage.id,
                estimate_request_id: vrnImage.estimate_request_id,
                image_url: vrnImage.image_url,
                description: vrnImage.description,
                contains_vrn: true
              };
            }
          }
        }

        setExistingDraft(draftData);
        setShowDraftDialog(true);
        }
      } catch (error) {
        console.error('Initialization error:', error);
        setApiError('Failed to initialize wizard');
      } finally {
        setIsLoading(false);
      }
    };

    initializeWizard();
  }, [dispatch]);

  const loadExistingDraft = () => {
    dispatch({ type: 'LOAD_DRAFT', draft: existingDraft });
    setShowDraftDialog(false);
  };

  const discardExistingDraft = async () => {
    try {
      await deleteDraftEstimate();
      setExistingDraft(null);
      setShowDraftDialog(false);
    } catch (error) {
      console.error('Error deleting draft:', error);
      setApiError('Failed to delete draft');
    }
  };

  const saveDraft = async () => {


    /**
* Saves the current wizard state as a draft estimate request.
* 
* For new drafts (201 response), we sync important read-only fields from the backend:
* - id: Unique identifier 
* - cost_net/vat/gross: costs are specified server side on creation
* 
* These fields are computed server-side and should only be set on initial creation.
* For subsequent updates (200 response), we preserve the existing values.
* 
* Incoming state validation:
* - Enforces 'DRAFT' status
* - Sets VRN_NOT_MATCH for new drafts
* - Ensures vehicle_year is a valid integer
*/


    if (!state.is_dirty) return true;

    setSavingDraft(true);
    setApiError(null);

    try {
      const estimateData = { ...state };

      // Ensure the status is set to 'DRAFT'
      estimateData.status = 'DRAFT';

      // Include the 'id' if it exists in the state
      if (state.id) {
        estimateData.id = state.id;
      } else {
        estimateData.vrn_image_status = 'VRN_NOT_MATCH'; // Set default VRN image status
      }

      // Convert vehicle_year to an integer
      if (estimateData.vehicle_year) {
        const parsedYear = parseInt(estimateData.vehicle_year, 10);
        if (!isNaN(parsedYear)) {
          estimateData.vehicle_year = parsedYear;
        } else {
          // Handle invalid year value
          estimateData.vehicle_year = null;
          console.error('Invalid vehicle year provided.');
        }
      }

      const response = await saveDraftEstimateRequest(estimateData);

      if (response.status === 201) {

 
        // New draft created
        const data = response.data;

        Object.entries(data).forEach(([key, value]) => {
          console.log(`${key}: ${value}`);
        });

        dispatch({ type: 'UPDATE_FIELD', field: 'id', value: data.id });
        dispatch({ type: 'UPDATE_FIELD', field: 'cost_net', value: data.cost_net });
        dispatch({ type: 'UPDATE_FIELD', field: 'cost_vat', value: data.cost_vat });
        dispatch({ type: 'UPDATE_FIELD', field: 'cost_gross', value: data.cost_gross });
        
        console.log('Draft estimate created successfully');

      } else if (response.status === 200) {
        console.log('Draft estimate updated successfully');
      }

      dispatch({ type: 'SET_DIRTY', is_dirty: false });
      return true;
    } catch (error) {
      console.error('Error saving draft estimate request:', error);
      setApiError('Failed to save draft');
      return false;
    } finally {
      setSavingDraft(false);
    }
  };

  const handleNext = async () => {
    if (state.current_step === 4) {
      // Do nothing; payment step handles its own navigation
      return;
    }

    const currentStepRef = stepRefs.current[state.current_step];
    if (!currentStepRef) return;

    try {
      setApiError(null);

      // Run validation
      const validationResult = await currentStepRef.validate();
      if (!validationResult.is_valid) {
        dispatch({
          type: 'SET_VALIDATION',
          validation: { errors: validationResult.errors },
        });
        return;
      }

      // Save draft if dirty
      if (state.is_dirty) {
        const saved = await saveDraft();
        if (!saved) return;
      }

      // Move to next step
      dispatch({ type: 'SET_STEP', step: state.current_step + 1 });
    } catch (error) {
      console.error('Navigation error:', error);
      setApiError('Failed to proceed to next step');
    }
  };

  const handleBack = async () => {
    if (state.current_step === 0) return;

    try {
      // Save draft if dirty
      if (state.is_dirty) {
        const saved = await saveDraft();
        if (!saved) return;
      }

      // Move to previous step
      dispatch({ type: 'SET_STEP', step: state.current_step - 1 });
    } catch (error) {
      console.error('Navigation error:', error);
      setApiError('Failed to go back to previous step');
    }
  };

  const handleCancel = () => {
    navigate('/dashboard'); // Navigate to dashboard or desired route
  };

  // Automatically proceed to completion after payment
  useEffect(() => {
    if (state.status === 'PAID' && state.current_step === 4) {
      dispatch({ type: 'SET_STEP', step: state.current_step + 1 });
    }
  }, [state.status, state.current_step, dispatch]);

  const currentStepComponent = () => {
    switch (state.current_step) {
      case 0:
        return (
          <EstWizStep1VehicleInfoRates
            ref={(el) => (stepRefs.current[0] = el)}
          />
        );
      case 1:
        return (
          <EstWizStep2ImageUpload
            ref={(el) => (stepRefs.current[1] = el)}
          />
        );
      case 2:
        return (
          <EstWizStep3DamageAreaSelector
            ref={(el) => (stepRefs.current[2] = el)}
          />
        );
      case 3:
        return (
          <EstWizStep4Review
            ref={(el) => (stepRefs.current[3] = el)}
          />
        );
      case 4:
        return (
          <EstWizStep5Payment />
        );
      case 5:
        return (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Thank you for your request!
            </Typography>
            <Typography variant="body1">
              Your estimate request has been submitted successfully.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/dashboard')}
              sx={{ mt: 3 }}
            >
              Go to Dashboard
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  // Determine if it's the last step (excluding the completion step)
  const isLastStep = state.current_step === steps.length - 1;

  return (

    <Paper elevation={2} sx={{ p: 0, borderRadius:2, marginTop: 0, width:"100%" }}>
    <Box sx={{ width: '100%', p: 0, pt:2 }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Visual Stepper */}


          <Stepper activeStep={state.current_step} alternativeLabel sx={{ marginBottom: 3}  }>
              {steps.map((label, index) => (
                <Step key={index} >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

          <Box
            
            p={3}
            borderRadius={0}
            marginTop={0}
            sx={{ width: '100%'}}
          >

            



            {/* Step Content */}
            <Box>{currentStepComponent()}</Box>

            {/* API Error */}
            {apiError && (
              <Typography color="error" align="center">
                {apiError}
              </Typography>
            )}

            {/* Wizard Controls */}
            {state.current_step < steps.length && state.current_step !== 4 && (
              <Box mt={2} display="flex" justifyContent="space-between">
                <Box>
                  {handleCancel && (
                    <Button onClick={handleCancel} variant="outlined" color="secondary" sx={{ mr: 1 }}>
                      Cancel
                    </Button>
                  )}
                  {state.current_step > 0 && (
                    <Button onClick={handleBack} variant="outlined" sx={{ mr: 1 }}>
                      Back
                    </Button>
                  )}
                </Box>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                  disabled={savingDraft}
                >
                  {savingDraft ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Next'
                  )}
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}

      {/* Draft Loading Dialog */}
      <Dialog open={showDraftDialog} onClose={() => setShowDraftDialog(false)}>
        <DialogTitle>Load Existing Draft</DialogTitle>
        <DialogContent>
          {existingDraft && (
            <Typography>
              Draft last modified: {new Date(existingDraft.updated_at).toLocaleString()}
              <br />
              Vehicle: {existingDraft.vehicle_make} {existingDraft.vehicle_model}
            </Typography>
          )}
          <Typography>Would you like to load your existing draft?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={discardExistingDraft} color="secondary">
            Start Fresh
          </Button>
          <Button onClick={loadExistingDraft} color="primary">
            Load Draft
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Paper>
  );
};

export default EstimateRequestWizard;