// axiosConfig.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

console.log('Environment variables:', {
  API_URL,
  NODE_ENV: process.env.NODE_ENV,
  allEnv: process.env
});

const USER_KEY = 'user';

// Token management
export const tokenManager = {
  getUser: () => {
    try {
      const userStr = localStorage.getItem(USER_KEY);
      return userStr ? JSON.parse(userStr) : null;
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  },
  setUser: (userData) => {
    localStorage.setItem(USER_KEY, JSON.stringify(userData));
  },
  removeUser: () => {
    localStorage.removeItem(USER_KEY);
  },
  getToken: () => {
    const user = tokenManager.getUser();
    return user?.token;
  }
};

// Create base axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 10000,
});

// Request interceptor


axiosInstance.interceptors.request.use(
  (config) => {
    const token = tokenManager.getToken();
    
    // Add more detailed logging
    console.log('Original API_URL:', API_URL);
    console.log('Config baseURL:', config.baseURL);
    console.log('Final request URL:', config.baseURL + config.url);
    
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);





axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      // Exactly match the login endpoint
      const loginEndpoint = '/auth/login'; 
      const isLoginAttempt = error.config.url === loginEndpoint || 
                            error.config.url.endsWith(loginEndpoint);
      
      if (!isLoginAttempt) {
        tokenManager.removeUser();
        window.location = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;