import Dashboard from './components/Dashboard';
import EstimateRequestWizard from './components/wizards/estimateRequest/EstimateRequestWizard';
import MyAccount from './components/MyAccount';
import AboutPage from './components/AboutPage';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import HomePage from './components/HomePage';
import AccountCreationWizard from './components/wizards/accountCreation/AccountCreationWizard';
import EstimateComplete from './components/EstimateComplete';
import EstimateDetail from './components/EstimateDetail';
import FileViewer from './components/FileViewer';

export const routes = [
  { path: '/', element: HomePage, isPublic: true },
  { path: '/login', element: Login, isPublic: true },
  { path: '/forgot-password', element: ForgotPassword, isPublic: true },
  { path: '/register', element: AccountCreationWizard, isPublic: true },
  { path: '/about', element: AboutPage, isPublic: true },
  { path: '/dashboard', element: Dashboard, isProtected: true },
  { path: '/request-estimate', element: EstimateRequestWizard, isProtected: true },
  { path: '/my-account', element: MyAccount, isProtected: true },
  { path: '/payment-confirmation', element: EstimateComplete, isProtected: true },
  { path: '/estimates/:estimateId', element: EstimateDetail, isProtected: true},
  { path: '/files/:blobName', element: FileViewer, isProtected: true },
];