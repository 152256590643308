// src/components/wizards/estimateRequest/EstimateRequestContext.js

import React, { createContext, useContext, useReducer } from 'react';

// Action Types
const UPDATE_FIELD = 'UPDATE_FIELD';
const SET_STEP = 'SET_STEP';
const SET_VALIDATION = 'SET_VALIDATION';
const SET_DIRTY = 'SET_DIRTY';
const LOAD_DRAFT = 'LOAD_DRAFT';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const ADD_DAMAGE_IMAGE = 'ADD_DAMAGE_IMAGE';
const REMOVE_DAMAGE_IMAGE = 'REMOVE_DAMAGE_IMAGE';


// Initial State
const initial_state = {
  // Core Data
  id: null,
  status: 'DRAFT',
  ref: null,

  // Vehicle Details
  vehicle_registration_number: '',
  vehicle_make: '',
  vehicle_model: '',
  vehicle_year: '',
  vehicle_color: '',
  raw_vehicle_data: '',

  // Rate Information
  estimate_platform: '',
 // Platform Account IDs
 platform_account_id: null,  //this will store the gt or auda id, depending on the platform selected

  labour_rate: 0,
  parts_discount: 0,
  paint_discount: 0,
  custom_rates: false,
  retail_menu_file: null,
  attach_menu_file: true,


  gt_global_account_id: null,
  audatex_account_id: null,

  // Profile Rates
  profile_labour_rate: 0,
  profile_parts_discount: 0,
  profile_paint_discount: 0,

  profile_retail_menu_file: null,

  // Images & VRN
  vrn_image: null,
  vrn_from_image: '',
  vrn_image_status: 'VRN_NOT_MATCH',
  damage_images: [],

  // Damage Details
  damage_description: '',
  affected_areas: {},

  // Payment
  payment_intent_id: null,
  payment_intent_secret: null,



  // Additional State
  current_step: 0,
  validation: {
    errors: {},
  },
  is_dirty: false,
};

// Reducer Function
function estimateRequestReducer(state, action) {
  switch (action.type) {
    case UPDATE_FIELD:
      return { ...state, [action.field]: action.value, is_dirty: true };

    case SET_STEP:
      return { ...state, current_step: action.step };

    case SET_VALIDATION:
      return { ...state, validation: action.validation };

    case SET_DIRTY:
      return { ...state, is_dirty: action.is_dirty };

    case LOAD_DRAFT:
      return {
        ...state,
        ...action.draft,
        is_dirty: false,
      };

    case SET_PROFILE_DATA:
      return {
        ...state,
        // Initialize rate fields to profile values if they are not set
        labour_rate: state.labour_rate || action.payload.default_labour_rate,
        parts_discount: state.parts_discount || action.payload.default_parts_discount,
        paint_discount: state.paint_discount || action.payload.default_paint_discount,
        // Initialize retail menu file to profile value if it is not set
        retail_menu_file: state.retail_menu_file || action.payload.default_retail_menu_file,



        // Store profile values separately
        profile_labour_rate: action.payload.default_labour_rate,
        profile_parts_discount: action.payload.default_parts_discount,
        profile_paint_discount: action.payload.default_paint_discount,
        gt_global_account_id: action.payload.gt_global_account_id,
        audatex_account_id: action.payload.audatex_account_id,
        profile_retail_menu_file: action.payload.default_retail_menu_file,

      };

    case ADD_DAMAGE_IMAGE:
      return {
        ...state,
        damage_images: [...state.damage_images, action.payload],
        is_dirty: true,
      } ;

    case REMOVE_DAMAGE_IMAGE:
      return {
        ...state,
        damage_images: state.damage_images.filter((img) => img.id !== action.payload.id),
        is_dirty: true,
      };

    default:
      return state;
  }
}

// Create Context
const EstimateRequestContext = createContext();

// Context Provider
export function EstimateRequestProvider({ children }) {
  const [state, dispatch] = useReducer(estimateRequestReducer, initial_state);
  return (
    <EstimateRequestContext.Provider value={{ state, dispatch }}>
      {children}
    </EstimateRequestContext.Provider>
  );
}

// Custom Hook
export function useEstimateRequest() {
  const context = useContext(EstimateRequestContext);
  if (context === undefined) {
    throw new Error('useEstimateRequest must be used within an EstimateRequestProvider');
  }
  return context;
}