// VDTitle.js
import React from 'react';
import { Typography } from '@mui/material';

const VDTitle = ({ children }) => (
  <Typography 
    variant="h6"  // Slightly smaller than h4
    gutterBottom
    align="center"
    color='primary'  // Text color
    sx={{ textTransform: 'uppercase' }}  // All caps
  >--&nbsp; 
    {children}
  &nbsp;-- </Typography>
);

export default VDTitle;