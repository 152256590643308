import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CloudUpload as UploadIcon } from '@mui/icons-material';

const RatesForm = ({ formData, onChange, errors }) => {
  const [fileName, setFileName] = useState(formData.default_retail_menu_file?.name || '');

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      onChange({ default_retail_menu_file: file });
    }
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        {/* Row 1: Main Rates */}
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="default_labour_rate"
            label="Default Labour Rate (£/hour)"
            name="default_labour_rate"
            type="number"
            value={formData.default_labour_rate || ''}
            onChange={handleChange}
            error={!!errors?.default_labour_rate}
            helperText={errors?.default_labour_rate}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="default_parts_discount"
            label="Default Parts Discount (%)"
            name="default_parts_discount"
            type="number"
            value={formData.default_parts_discount || ''}
            onChange={handleChange}
            error={!!errors?.default_parts_discount}
            helperText={errors?.default_parts_discount}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="default_paint_discount"
            label="Default Paint Discount (%)"
            name="default_paint_discount"
            type="number"
            value={formData.default_paint_discount || ''}
            onChange={handleChange}
            error={!!errors?.default_paint_discount}
            helperText={errors?.default_paint_discount}
          />
        </Grid>

        {/* Row 2: File Upload */}
        <Grid size={{ xs: 12 }}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <input
              accept=".pdf,.jpg,.png"
              style={{ display: 'none' }}
              id="retail-menu-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="retail-menu-file">
              <Button
                variant="outlined"
                component="span"
                startIcon={<UploadIcon />}
                sx={{ mb: 1 }}
              >
                Upload Retail Menu (Optional)
              </Button>
            </label>
            {fileName && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                Selected file: {fileName}
              </Typography>
            )}
          </Box>
        </Grid>

        {/* Row 3: Account IDs */}
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            fullWidth
            id="audatex_account_id"
            label="Audatex Account ID (Optional)"
            name="audatex_account_id"
            value={formData.audatex_account_id || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            margin="normal"
            fullWidth
            id="gt_global_account_id"
            label="GT Global Account ID (Optional)"
            name="gt_global_account_id"
            value={formData.gt_global_account_id || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RatesForm;