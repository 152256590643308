// src/components/TermsOfService.js

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Terms of Service
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
        Last Updated: November 11, 2024
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Service ("Terms") govern your use of the Vehicle Damage Assessment System ("VDAS", "the Service", "we", "our", or "us"). By using VDAS, you agree to these Terms. If you disagree with any part of these Terms, please do not use our Service.
        </Typography>
        <Typography variant="body1" paragraph>
          This agreement is governed by the laws of England and Wales.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. The Service
        </Typography>
        <Typography variant="body1" paragraph>
          VDAS provides vehicle damage assessment estimates based on industry data and best practices. Our Service:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Processes photographs and information you provide about vehicle damage</Typography>
          </li>
          <li>
            <Typography variant="body1">Generates estimates based on available industry pricing data and assessment methodologies</Typography>
          </li>
          <li>
            <Typography variant="body1">Delivers estimates within target timeframes, subject to service conditions</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          3. Nature of Estimates
        </Typography>
        <Typography variant="body1" paragraph>
          It is important to understand that:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Our estimates are guidelines only and not formal quotations</Typography>
          </li>
          <li>
            <Typography variant="body1">Estimates are provided "as is" without any warranties or guarantees</Typography>
          </li>
          <li>
            <Typography variant="body1">Actual repair costs may vary from our estimates</Typography>
          </li>
          <li>
            <Typography variant="body1">We do not guarantee the accuracy of estimates</Typography>
          </li>
          <li>
            <Typography variant="body1">Estimates are based on visible damage from supplied photographs and information only</Typography>
          </li>
          <li>
            <Typography variant="body1">Hidden damage may affect final repair costs</Typography>
          </li>
          <li>
            <Typography variant="body1">Our estimates do not constitute a binding agreement to perform repairs</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          4. Service Timeframes
        </Typography>
        <Typography variant="body1" paragraph>
          While we aim to provide estimates quickly:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Our stated turnaround times are targets only and not guaranteed</Typography>
          </li>
          <li>
            <Typography variant="body1">Various factors may affect processing times</Typography>
          </li>
          <li>
            <Typography variant="body1">If we cannot provide an estimate within 3 business days, we will automatically refund your payment in full</Typography>
          </li>
          <li>
            <Typography variant="body1">Refunds will be processed to the original payment method</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          5. Your Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Provide accurate and complete information about the vehicle damage</Typography>
          </li>
          <li>
            <Typography variant="body1">Submit clear, well-lit photographs showing all damaged areas</Typography>
          </li>
          <li>
            <Typography variant="body1">Supply any additional information requested to complete the estimate</Typography>
          </li>
          <li>
            <Typography variant="body1">Pay the specified fee before receiving the estimate</Typography>
          </li>
          <li>
            <Typography variant="body1">Use the Service for legitimate purposes only</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          6. Limitations of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          We limit our liability as follows:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">We are not responsible for repair costs exceeding our estimates</Typography>
          </li>
          <li>
            <Typography variant="body1">We do not accept liability for decisions made based on our estimates</Typography>
          </li>
          <li>
            <Typography variant="body1">Our maximum liability is limited to the fee paid for the specific estimate</Typography>
          </li>
          <li>
            <Typography variant="body1">We are not liable for indirect, consequential, or incidental damages</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          7. Payment and Refunds
        </Typography>
        <Typography variant="body1" paragraph>
          Regarding payments:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Fees must be paid in advance</Typography>
          </li>
          <li>
            <Typography variant="body1">We accept major credit and debit cards</Typography>
          </li>
          <li>
            <Typography variant="body1">Refunds are provided only if:</Typography>
            <ul>
              <li>
                <Typography variant="body1">We cannot deliver the estimate within 3 business days</Typography>
              </li>
              <li>
                <Typography variant="body1">Technical issues prevent us from completing the estimate</Typography>
              </li>
              <li>
                <Typography variant="body1">Required by applicable law</Typography>
              </li>
            </ul>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          8. Data Protection and Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          We handle your data in accordance with:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">The UK Data Protection Act 2018</Typography>
          </li>
          <li>
            <Typography variant="body1">UK GDPR requirements</Typography>
          </li>
          <li>
            <Typography variant="body1">Our Privacy Policy (available separately)</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          9. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          The Service, including all software, algorithms, and processes, remains our property. Estimates are provided for your personal use only. You may not reproduce or distribute our estimates without permission.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Service Modifications
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Modify these Terms with reasonable notice</Typography>
          </li>
          <li>
            <Typography variant="body1">Change our pricing structure</Typography>
          </li>
          <li>
            <Typography variant="body1">Update our estimation methodology</Typography>
          </li>
          <li>
            <Typography variant="body1">Modify or discontinue features of the Service</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          11. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend access to our Service immediately if:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">You breach these Terms</Typography>
          </li>
          <li>
            <Typography variant="body1">You provide false information</Typography>
          </li>
          <li>
            <Typography variant="body1">You use the Service fraudulently</Typography>
          </li>
          <li>
            <Typography variant="body1">Required by law or regulation</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          12. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          For questions about these Terms:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Email: support@vdas.com</Typography>
          </li>
          <li>
            <Typography variant="body1">Phone: [UK phone number]</Typography>
          </li>
          <li>
            <Typography variant="body1">Address: [UK business address]</Typography>
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          13. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms are governed by the laws of England and Wales. Any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales.
        </Typography>

        <Typography variant="body1" paragraph>
          By using VDAS, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;