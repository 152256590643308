// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Container, Box } from '@mui/material';
import theme from './theme';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { routes } from './routesConfig';
import { EstimateRequestProvider } from './components/wizards/estimateRequest/EstimateRequestContext';


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  // Show nothing while auth state is being determined
  if (loading) {
    return null; // or return a loading spinner
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        {/* Removed the global Elements provider */}
        <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
          <Router>
            <NavMenu />
            <Container sx={{ py: 1, flex: 1, px: 1 }} disableGutters>
              <Routes>
                {routes.map((route) => {
                  const Element = route.element;
                  let elementToRender = route.isProtected ? (
                    <ProtectedRoute>
                      <Element />
                    </ProtectedRoute>
                  ) : (
                    <Element />
                  );

                  // Wrap with EstimateRequestProvider if path is '/request-estimate'
                  if (route.path === '/request-estimate') {
                    elementToRender = (
                      <EstimateRequestProvider>
                        {elementToRender}
                      </EstimateRequestProvider>
                    );
                  }

                  return (
                    <Route key={route.path} path={route.path} element={elementToRender} />
                  );
                })}

                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Container>
            <Footer/>
          </Router>
        </Box>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;