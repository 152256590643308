// src/components/wizards/estimateRequest/EstWizStep3DamageAreaSelector.js

import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  Typography,
  Chip,
  Container,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEstimateRequest } from './EstimateRequestContext';
import { ReactComponent as CarDiagram } from '../../../assets/car-damage-template.svg';
import { useTheme, useMediaQuery } from '@mui/material';


const initialDamageProfile = {
  "bonnet": false,
  "offside-front-wing": false,
  "offside-front-door": false,
  "nearside-front-door": false,
  "roof": false,
  "offside-back-door": false,
  "nearside-front-wing": false,
  "nearside-back-door": false,
  "offside-back-wing": false,
  "nearside-back-wing": false,
  "rear-bumper": false,
  "front-bumper": false,
  "tailgate": false,
};

const CarDiagramWrapper = ({ onPartClick, getPartColor }) => {
  useEffect(() => {
    const paths = document.querySelectorAll('svg path[id]');
    paths.forEach((path) => {
      const partId = path.id;
      path.style.cursor = 'pointer';
      path.style.fill = getPartColor(partId);
      path.onclick = () => onPartClick(partId);
    });
  }, [getPartColor, onPartClick]);

  return <CarDiagram />;
};

const EstWizStep3DamageAreaSelector = forwardRef((props, ref) => {
  const { state, dispatch } = useEstimateRequest();
  const [validationErrors, setValidationErrors] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Initialize affected_areas if not set
  useEffect(() => {
    if (!state.affected_areas) {
      dispatch({
        type: 'UPDATE_FIELD',
        field: 'affected_areas',
        value: initialDamageProfile,
      });
    }
  }, [state.affected_areas, dispatch]);

  // Expose validate method to the wizard
  useImperativeHandle(ref, () => ({
    validate: () => {
      const errors = {};

      const isAnyAreaSelected = Object.values(state.affected_areas || {}).some(
        (value) => value === true
      );
      if (!isAnyAreaSelected) {
        errors.affected_areas = 'Please select at least one damage area.';
      }

      if (!state.damage_description || state.damage_description.trim() === '') {
        errors.damage_description = 'Please provide a description of the damage.';
      }

      setValidationErrors(errors);

      const isValid = Object.keys(errors).length === 0;
      return { is_valid: isValid, errors };
    },
  }));

  const handleDamageDescriptionChange = (event) => {
    dispatch({
      type: 'UPDATE_FIELD',
      field: 'damage_description',
      value: event.target.value,
    });
    // Clear validation error for damage_description when user types
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      damage_description: undefined,
    }));
  };

  const handlePartClick = (partId) => {
    const updatedAffectedAreas = {
      ...state.affected_areas,
      [partId]: !state.affected_areas[partId],
    };
    dispatch({
      type: 'UPDATE_FIELD',
      field: 'affected_areas',
      value: updatedAffectedAreas,
    });
    // Clear validation error for affected_areas when a part is clicked
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      affected_areas: undefined,
    }));
  };

  const getPartColor = (partId) => {
    return state.affected_areas && state.affected_areas[partId] ? 'red' : '#808080';
  };

  return (

      <Grid
        container
        spacing={2}
      >
        {/* First Column - Damage Description */}


        <Grid size="grow">
       

        <Paper
          elevation={3}
          sx={{
            p: 3,
            // Remove fixed height to allow dynamic sizing
            // height: '100%', 

            mx: { xs: 0, md: 'auto' },
          }}
        >
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Damage Description"
            value={state.damage_description || ''}
            onChange={handleDamageDescriptionChange}
            error={!!validationErrors.damage_description}
            helperText={validationErrors.damage_description}
          />
        </Paper>

        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>

          <Paper
            elevation={3}
            sx={{
              p: { xs: 1, md: 2 }, // Responsive padding: 8px on mobile, 16px on desktop

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // Removed height: '100%' to allow dynamic height on mobile
              // Removed flexGrow: 1 to prevent width conflicts
            }}
          >



            <Box
              sx={{
                width: '100%',
                height: { xs: '400px', md: '320px' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                  height: '100%',
                  width: 'auto',
                  maxWidth: '100%',
                  objectFit: 'contain',
                },
              }}
            >
              <CarDiagramWrapper
                onPartClick={handlePartClick}
                getPartColor={getPartColor}
              />
            </Box>
          </Paper>

          </Grid>


          <Grid size="grow">
          <Paper
            elevation={3}
            sx={{
              p: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                maxWidth: '100%',
              }}
            >
              {Object.entries(state.affected_areas || {})
                .filter(([, isSelected]) => isSelected)
                .map(([area]) => (
                  <Chip
                    key={area}
                    label={area.replace(/-/g, ' ')}
                    onDelete={() => handlePartClick(area)}
                    color="primary"
                    sx={{ flexShrink: 0 }}
                  />
                ))}
            </Box>

            {/* Display validation error for affected_areas */}
            {validationErrors.affected_areas && (
              <Typography color="error" variant="body2">
                {validationErrors.affected_areas}
              </Typography>
            )}
          </Paper>
          </Grid>



      </Grid>

  );
});

export default EstWizStep3DamageAreaSelector;