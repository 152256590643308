import React, { createContext, useState, useContext, useEffect } from 'react';
import AuthService from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkAuthStatus = () => {
    const currentUser = AuthService.getCurrentUser();
    const isAuth = AuthService.isAuthenticated();
    setIsAuthenticated(isAuth);
    setUser(currentUser);
    setLoading(false);
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const login = async (email, password, rememberMe = undefined) => {
    try {
      setError(null);
      // Only pass rememberMe if it's explicitly set
      const args = [email, password];
      if (typeof rememberMe === 'boolean') {
        args.push(rememberMe);
      }
      
      await AuthService.login(...args);
      checkAuthStatus();
      return true;
    } catch (error) {
      // Now we can just use error.message since AuthService formats it
      setError(error.message);
      console.error('Login failed:', error.message);
      return false;
    }
};
  const logout = async () => {
    try {
      await AuthService.logout();
      setIsAuthenticated(false);
      setUser(null);
      setError(null);
    } catch (error) {
      console.error('Logout failed:', error);
      // Still clear the local state even if the server request fails
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  const register = async (userData) => {
    try {
      setError(null);
      await AuthService.register(userData);
      checkAuthStatus();
      return true;
    } catch (error) {
      setError(error.response?.data?.error || 'Registration failed');
      console.error('Registration failed:', error);
      return false;
    }
  };

  const updateProfile = async (userData, file) => {
    try {
      setError(null);
      const response = await AuthService.updateProfile(userData, file);
      checkAuthStatus();
      return response.data;
    } catch (error) {
      setError(error.response?.data?.error || 'Profile update failed');
      console.error('Profile update failed:', error);
      throw error;
    }
  };

  const forgotPassword = async (email) => {
    try {
      setError(null);
      return await AuthService.forgotPassword(email);
    } catch (error) {
      setError(error.response?.data?.error || 'Password reset request failed');
      throw error;
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      setError(null);
      return await AuthService.resetPassword(token, newPassword);
    } catch (error) {
      setError(error.response?.data?.error || 'Password reset failed');
      throw error;
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      setError(null);
      return await AuthService.changePassword(oldPassword, newPassword);
    } catch (error) {
      setError(error.response?.data?.error || 'Password change failed');
      throw error;
    }
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      user,
      loading,
      error,
      login,
      logout,
      register,
      updateProfile,
      forgotPassword,
      resetPassword,
      changePassword,
      checkAuthStatus,
      clearError
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;