import React, { useState, useEffect } from 'react';
import {
  Container, 
  Box, 
  Typography, 
  CircularProgress, 
  Button, 
  Stack, 
  Paper, 
  Divider,
  TextField, Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import VDTitle from './VDTitle';
import {  getFileContent } from '../services/api.js';

const MyAccount = () => {
  const { user, updateProfile, logout, checkAuthStatus } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Initialize form values whenever user changes
  useEffect(() => {
    if (user) {

      console.log('Raw user object:', user);
      console.log('User profile:', JSON.stringify(user.user_profile, null, 2));
       console.table(user.user_profile); // Table view for object properties
//debugger; // Will pause execution in browser dev tools

      setFormValues(user.user_profile);
    }
  }, [user]);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };


  const handleFileView = async (fileUrl) => {
    try {
      const fileContent = await getFileContent(fileUrl);
      const blob = new Blob([fileContent], { 
        type: fileUrl.toLowerCase().endsWith('.pdf') ? 'application/pdf' : 'image/jpeg' 
      });
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl, '_blank');
      
      // Clean up the object URL after window opens
      setTimeout(() => URL.revokeObjectURL(objectUrl), 100);
    } catch (error) {
      console.error('Error loading file:', error);
    }
  };

  const handleEditToggle = () => {
    if (editMode) {
      // Exiting edit mode - reset to current profile
      setFormValues(user.user_profile);
    } else {
      // Entering edit mode - set up form values
      setFormValues({
        ...user.user_profile,
        default_retail_menu_file: null // Reset file input
      });
    }
    setError(null);
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(curr => ({
      ...curr,
      [name]: name === 'new_retail_menu_file' ? value : value,
    }));
  };
  const handleSave = async () => {
    setSaving(true);
    setError(null);
    
    try {
      // Handle file separately if it exists
      const retailMenuFile = formValues.new_retail_menu_file || null;
      const profileData = { ...formValues };
      delete profileData.new_retail_menu_file;
      
      await updateProfile(profileData, retailMenuFile);
      
      // Refresh profile to ensure we have latest data
      await checkAuthStatus();
      
      setEditMode(false);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError(err?.message || 'Failed to update account. Please try again later.');
    } finally {
      setSaving(false);
    }
  };

  const renderRetailMenuFile = () => {
    if (editMode) {
      return (
        <Box>
          {user.user_profile.default_retail_menu_file && (
            <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>

              <Button 
                component="a"
                href={`${process.env.REACT_APP_API_URL}/estimates/file-content-binary/${user.user_profile.default_retail_menu_file}`}
                variant="text"
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download current file
              </Button>
            </Stack>
          )}
          
          <input
            type="file"
            accept=".pdf,.jpg,.jpeg,.png,.gif"
            style={{ display: 'none' }}
            id="new_retail_menu_file"
            onChange={(e) => handleChange({
              target: { 
                name: 'new_retail_menu_file', 
                value: e.target.files[0] 
              }
            })}
            />
            <label htmlFor="new_retail_menu_file">
            <Button variant="outlined" component="span">
              {user.user_profile.default_retail_menu_file ? 'Replace File' : 'Upload File'}
            </Button>
            </label>
          
          <Typography variant="body2" sx={{ mt: 1 }}>
            PDF or Image formats only
          </Typography>
          
          {formValues.new_retail_menu_file && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected file: {formValues.new_retail_menu_file.name}
            </Typography>
          )}
        </Box>
      );
    } else {
      return (
        <Typography>
          <strong>Default Retail Menu File:</strong>{' '}
          {user.user_profile.default_retail_menu_file ? (
            <Button 
  onClick={() => handleFileView(user.user_profile.default_retail_menu_file)}
  variant="text"
  color="primary"
>
  View file
</Button>
          ) : (
            'None Specified'
          )}
        </Typography>
      );
    }
  };  

  if (!user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Container maxWidth="md">
      <VDTitle>VDAS Profile</VDTitle>
      <Box sx={{ p: 0 }}>
        <Stack spacing={2}>
          {/* Edit Controls */}
          <Stack direction="row" spacing={2} alignItems="center">
            <Button variant="outlined" onClick={handleLogout}>
              Logout
            </Button>
            <Button variant="contained" onClick={handleEditToggle}>
              {editMode ? 'Cancel' : 'Edit'}
            </Button>
          </Stack>
  
          {/* Content Sections */}
          <Stack spacing={2}>
            {/* Company Information */}
            <Paper elevation={4} sx={{ p: 2, borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom color="primary">
                Company Information
              </Typography>
  
              {editMode ? (
                <Stack spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    label="Full Name"
                    name="full_name"
                    value={formValues.full_name || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="Email"
                    name="email"
                    value={formValues.email || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                    disabled  // Email should not be editable
                  />
                  <TextField
                    label="Phone Number"
                    name="phone_number"
                    value={formValues.phone_number || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="Company Name"
                    name="entity_name"
                    value={formValues.entity_name || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="Audatex Account Id"
                    name="audatex_account_id"
                    value={formValues.audatex_account_id || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="GT Global Account Id"
                    name="gt_global_account_id"
                    value={formValues.gt_global_account_id || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                </Stack>
              ) : (
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <Typography>
                    <strong>Full Name:</strong> {user.user_profile.full_name || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {user.user_profile.email || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Phone Number:</strong> {user.user_profile.phone_number || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Company Name:</strong> {user.user_profile.entity_name || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Audatex Account Id:</strong> {user.user_profile.audatex_account_id || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>GT Global Account Id:</strong> {user.user_profile.gt_global_account_id || 'N/A'}
                  </Typography>
                </Stack>
              )}
            </Paper>
  
            {/* Address Information */}
            <Paper elevation={4} sx={{ p: 2, borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom color="primary">
                Address
              </Typography>
  
              {editMode ? (
                <Stack spacing={2}>
                  <TextField
                    label="Street"
                    name="street"
                    value={formValues.street || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="City"
                    name="city"
                    value={formValues.city || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="County"
                    name="county"
                    value={formValues.county || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="Postcode"
                    name="postcode"
                    value={formValues.postcode || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                </Stack>
              ) : (
                <Stack spacing={1}>
                  <Typography>
                    <strong>Street:</strong> {user.user_profile.street || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>City:</strong> {user.user_profile.city || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>County:</strong> {user.user_profile.county || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Postcode:</strong> {user.user_profile.postcode || 'N/A'}
                  </Typography>
                </Stack>
              )}
            </Paper>
  
            {/* Commercial Settings */}
            <Paper elevation={4} sx={{ p: 2, borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom color="primary">
                Commercial Settings
              </Typography>
  
              {editMode ? (
                <Stack spacing={2}>
                  <TextField
                    label="Default Labour Rate (£/hour)"
                    name="default_labour_rate"
                    type="number"
                    value={formValues.default_labour_rate || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="Default Parts Discount (%)"
                    name="default_parts_discount"
                    type="number"
                    value={formValues.default_parts_discount || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    label="Default Paint Discount (%)"
                    name="default_paint_discount"
                    type="number"
                    value={formValues.default_paint_discount || ''}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                {renderRetailMenuFile()}
                </Stack>
              ) : (
                <Stack spacing={1}>
                  <Typography>
                    <strong>Default Labour Rate:</strong> £{user.user_profile.default_labour_rate || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Default Parts Discount:</strong> {user.user_profile.default_parts_discount || 'N/A'}%
                  </Typography>
                  <Typography>
                    <strong>Default Paint Discount:</strong> {user.user_profile.default_paint_discount || 'N/A'}%
                  </Typography>
                  {renderRetailMenuFile()}
                </Stack>
              )}
            </Paper>
          </Stack>
  
          {/* Action Buttons */}
          {editMode && (
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? 'Saving...' : 'Save'}
              </Button>
              <Button variant="outlined" onClick={handleEditToggle}>
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default MyAccount;