// src/components/FileViewer.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFileContent } from '../services/api';

const FileViewer = () => {
  const { blobName } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadFile = async () => {
      try {
        const blob = await getFileContent(blobName);
        // Create URL and redirect to it
        window.location.href = URL.createObjectURL(blob);
      } catch (err) {
        setError('File not found or access denied');
      } finally {
        setLoading(false);
      }
    };

    loadFile();
  }, [blobName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return null;
};

export default FileViewer;