import React, { useState } from 'react';
import { 
  Box, 
  Stepper, 
  Step, 
  StepLabel, 
  Button, 
  Typography, 
  Paper,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BasicInfoForm from './BasicInfoForm';
import RatesForm from './RatesForm';
import TermsOfService from '../../../components/TermsOfService';
import { useAuth } from '../../../contexts/AuthContext';
import VDTitle from '../../VDTitle';
import ErrorAlert from './ErrorAlert';

const steps = ['Basic Information', 'Rates and Retail Price Info'];

const AccountCreationWizard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [submitError, setSubmitError] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    full_name: '',
    entity_name: '',
    phone_number: '',
    street: '',
    city: '',
    county: '',
    postcode: '',
    default_labour_rate: '',
    default_parts_discount: '',
    default_paint_discount: '',
    default_retail_menu_file: null,
    gt_global_account_id: '',
    audatex_account_id: '',
  });

  const navigate = useNavigate();
  const { login, register } = useAuth();


  const validateRates = (data) => {
    const errors = {};
    
    if (!data.default_labour_rate) errors.default_labour_rate = 'Labour rate is required';
    if (!data.default_parts_discount) errors.default_parts_discount = 'Parts discount is required';
    if (!data.default_paint_discount) errors.default_paint_discount = 'Paint discount is required';
    
    // Numeric validation
    if (data.default_labour_rate && (isNaN(data.default_labour_rate) || data.default_labour_rate < 0)) {
      errors.default_labour_rate = 'Please enter a valid labour rate';
    }
    if (data.default_parts_discount && (isNaN(data.default_parts_discount) || data.default_parts_discount < 0 || data.default_parts_discount > 100)) {
      errors.default_parts_discount = 'Please enter a valid discount percentage (0-100)';
    }
    if (data.default_paint_discount && (isNaN(data.default_paint_discount) || data.default_paint_discount < 0 || data.default_paint_discount > 100)) {
      errors.default_paint_discount = 'Please enter a valid discount percentage (0-100)';
    }
    
    return errors;
};

  const validateBasicInfo = (data) => {
    const errors = {};
    
    if (!data.email) errors.email = 'Email is required';
    if (!data.password) errors.password = 'Password is required';
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    if (!data.full_name) errors.full_name = 'Full name is required';
    if (!data.entity_name) errors.entity_name = 'Company name is required';
    if (!data.phone_number) errors.phone_number = 'Phone number is required';
    if (!data.street) errors.street = 'Street address is required';
    if (!data.city) errors.city = 'City is required';
    if (!data.county) errors.county = 'County is required';
    if (!data.postcode) errors.postcode = 'Postcode is required';

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (data.email && !emailRegex.test(data.email)) {
      errors.email = 'Please enter a valid email address';
    }

    // Password validation
    if (data.password && data.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    // UK postcode validation
    const postcodeRegex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i;
    if (data.postcode && !postcodeRegex.test(data.postcode)) {
      errors.postcode = 'Please enter a valid UK postcode';
    }

    return errors;
  };

  const clearError = (fieldName) => {
    setFormErrors(prev => ({
      ...prev,
      [fieldName]: undefined
    }));
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      const errors = validateBasicInfo(formData);
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
    } else if (activeStep === steps.length - 1) {
      const errors = validateRates(formData);
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      await handleRatesSubmit(formData);
      return;
    }
    
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setFormErrors({});
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBasicInfoSubmit = async (data) => {
    setFormData(prev => ({ ...prev, ...data }));
    handleNext();
  };


  const handleRatesSubmit = async (data) => {
    try {
      setSubmitError(null); // Clear any previous errors
      const finalFormData = { ...formData, ...data };
      
      // Attempt to register and check the return value
      const success = await register(finalFormData);
      
      // Only navigate if registration was successful
      if (success) {
        navigate('/dashboard?message=ACCOUNT_CREATED');
      } else {
        setSubmitError('Registration failed. Please try again or contact support if the issue persists.');
      }
    } catch (error) {
      console.error('Error creating account:', error);
      setSubmitError(error.response?.data?.message || error.message || 'Failed to create account');
    }
  };



  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>

          <div style={{ padding:0, marginBottom: 24, lineHeight: '1.5' }}>
                <Typography variant="body2" style={{ display: 'inline' }}>
                  By signing up to VDAS you agree to our{' '}
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleClickOpen}
                    style={{ padding: 0, verticalAlign: 'baseline' }}
                  >
                    Terms of Service
                  </Link>
                </Typography>
              </div>

            <BasicInfoForm 
              formData={formData}
              onChange={(data) => {
                setFormData(prev => ({ ...prev, ...data }));
                if (data && Object.keys(data)[0]) {
                  clearError(Object.keys(data)[0]);
                }
              }}
              errors={formErrors}
            />
          </Box>
        );
      case 1:
        return (
          <RatesForm 
            formData={formData}
            onChange={(data) => {
              setFormData(prev => ({ ...prev, ...data }));
              if (data && Object.keys(data)[0]) {
                clearError(Object.keys(data)[0]);
              }
            }}
            errors={formErrors}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Paper elevation={2} sx={{ p: 2, borderRadius: 2, marginTop: 0, width: "100%" }}>
      <Box sx={{ width: '100%', p: 0 }}>
        <VDTitle>Register</VDTitle>
          {submitError && (
            <ErrorAlert 
              error={submitError} 
              onDismiss={() => setSubmitError(null)}
            />
          )}   
        <Stepper activeStep={activeStep} sx={{ maxWidth: '100%', margin: '0 auto' }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 3, mb: 1 }}>{getStepContent(activeStep)}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>

      {/* Terms of Service Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>VDAS Terms of Service</DialogTitle>
        <DialogContent>
          <TermsOfService />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AccountCreationWizard;