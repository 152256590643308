import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../contexts/AuthContext';
import { ReactComponent as LogoNoIcon } from '../assets/logo-and-text.svg';

const NavMenu = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated, logout, user } = useAuth();

  const publicMenuItems = !isAuthenticated
  ? [
      { text: 'Home', path: '/' },
      { text: 'About VDAS', path: '/about' }
    ]
  : [];

  const privateMenuItems = isAuthenticated ? [
    { text: 'Dashboard', path: '/dashboard' },
    { text: 'Get an Estimate', path: '/request-estimate' },
    { text: 'Profile', path: '/my-account' },
  ] : [];

  const authMenuItems = isAuthenticated ? [
    { text: 'Logout', onClick: () => { logout(); navigate('/login'); } }
  ] : [
    { text: 'Login', path: '/login' },
    { text: 'Register', path: '/register' }
  ];

  const allMenuItems = [...publicMenuItems, ...privateMenuItems];

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Mobile: Burger menu and logo */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', flexGrow: 1 }}>
            <IconButton
              size="large"
              onClick={(e) => setAnchorElNav(e.currentTarget)}
              color="inherit"
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component={RouterLink}
              to="/"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LogoNoIcon style={{ width: '90px', height: 'auto' }} />
            </Box>
          </Box>

          {/* Desktop: Logo */}
          <Box
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
            }}
          >
            <LogoNoIcon style={{ width: '130px', height: 'auto' }} />

          </Box>

          {/* Mobile: Menu */}
          <Menu
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={() => setAnchorElNav(null)}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            {[...allMenuItems, ...authMenuItems].map((item) => (
              <MenuItem
                key={item.text}
                onClick={() => {
                  setAnchorElNav(null);
                  item.onClick ? item.onClick() : navigate(item.path);
                }}
              >
                <Typography textAlign="center">{item.text}</Typography>
              </MenuItem>
            ))}
          </Menu>

          {/* Desktop: Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {allMenuItems.map((item) => (
              <Button
                key={item.text}
                component={RouterLink}
                to={item.path}
                variant="nav"
                sx={{ mx: 1 }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* Desktop: Auth buttons */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          {isAuthenticated && user && (
  <>
    <Typography sx={{ mr: 2 }}>
      {user.fullName}
    </Typography>
    {Object.entries(user).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
    })}
  </>
)}
            {authMenuItems.map((item) => (
              <Button
                key={item.text}
                variant="nav"
                component={item.path ? RouterLink : 'button'}
                to={item.path}
                onClick={item.onClick}
                sx={{ mx: 1 }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavMenu;
