import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
 TextField, 
 Button, 
 Typography, 
 Box,
 FormControlLabel,
 Checkbox,
 CircularProgress,
 Paper
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import VDTitle from './VDTitle';

const Login = () => {
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const [rememberMe, setRememberMe] = useState(false);
 const [isSubmitting, setIsSubmitting] = useState(false);
 const [localError, setLocalError] = useState('');
 const navigate = useNavigate();
 
 const { 
   login, 
   isAuthenticated, 
   loading, 
   error: authError,
   clearError,
   user 
 } = useAuth();

 useEffect(() => {
   if (isAuthenticated && user) {
     navigate('/dashboard');
   }
 }, [isAuthenticated, user, navigate]);

 const handleEmailChange = (e) => {
   setEmail(e.target.value);
   setLocalError('');
   if (authError) clearError();
 };

 const handlePasswordChange = (e) => {
   setPassword(e.target.value);
   setLocalError('');
   if (authError) clearError();
 };

 const handleSubmit = async (e) => {
  console.log('1. handleSubmit started');
  e.preventDefault();
  console.log('2. default prevented');
  
  if (!email || !password) {
      setLocalError('Email and password are required');
      return;
  }
  
  if (isSubmitting) {
      console.log('already submitting, returning');
      return;
  }
  
  setIsSubmitting(true);
  console.log('3. isSubmitting set to true');
  
  try {
      console.log('4. attempting login');
      const success = await login(email, password, rememberMe);
      console.log('5. login completed, success:', success);
      if (!success) {
          // If login returns false, don't proceed
          return;
      }
  } catch (error) {
      console.log('6. caught error:', error);
      return; // Make sure we return on error
  } finally {
      console.log('7. setting isSubmitting false');
      setIsSubmitting(false);
  }
};

 if (loading) {
   return (
     <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
       <CircularProgress />
     </Box>
   );
 }

 return (
   <Paper elevation={2} sx={{ p: 2, borderRadius: 2, marginTop: 0, width: "100%" }}>
     <Box component="form" onSubmit={handleSubmit} noValidate>
       <VDTitle>Sign in</VDTitle>
       <TextField
         fullWidth
         margin="normal"
         label="Email"
         type="email"
         value={email}
         onChange={handleEmailChange}
         required
         disabled={isSubmitting}
       />
       <TextField
         fullWidth
         margin="normal"
         label="Password"
         type="password"
         value={password}
         onChange={handlePasswordChange}
         required
         disabled={isSubmitting}
       />
       <FormControlLabel
         control={
           <Checkbox
             checked={rememberMe}
             onChange={(e) => setRememberMe(e.target.checked)}
             color="primary"
             disabled={isSubmitting}
           />
         }
         label="Remember me"
         sx={{ mt: 1 }}
       />
       {(localError || authError) && (
         <Typography color="error" sx={{ mt: 2 }}>
           {localError || authError}
         </Typography>
       )}
       <Button
         type="submit"
         variant="contained"
         color="primary"
         fullWidth 
         disabled={isSubmitting} 
         sx={{ mt: 3 }}
       >
         {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Sign in'}  
       </Button>
       <Box sx={{ mt: 2, textAlign: 'center' }}>
         <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
           <Typography color="primary" variant="body2">
             Forgot Password?
           </Typography>
         </Link>
       </Box>
     </Box>
   </Paper>
 );
};

export default Login;