import React, { useEffect, useState, useRef } from 'react';
import { Box, Paper, Typography, Divider, CircularProgress, Alert, Button } from '@mui/material';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useEstimateRequest } from './EstimateRequestContext';
import { createPaymentIntent } from '../../../services/api';
import { stripePromise, stripeElementsOptions } from '../../../stripeConfig';

const PaymentForm = ({ setError, error }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [validationError, setValidationError] = useState(null);

  // Reset validation error when user modifies the form
  useEffect(() => {
    if (!elements) return;

    const element = elements.getElement(PaymentElement);
    if (!element) return;

    const handleChange = (event) => {
      if (event.complete) {
        setValidationError(null);
      }
    };

    element.on('change', handleChange);
    return () => {
      element.off('change', handleChange);
    };
  }, [elements]);

  const validateForm = async () => {
    if (!stripe || !elements) {
      return { isValid: false, error: 'Payment system not initialized' };
    }

    const { error } = await elements.submit();
    if (error) {
      return { 
        isValid: false, 
        error: error.message || 'Please check your card details'
      };
    }

    return { isValid: true };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Clear previous errors
    setError(null);
    setValidationError(null);

    // Validate form before processing
    const validation = await validateForm();
    if (!validation.isValid) {
      setValidationError(validation.error);
      return;
    }

    try {
      setProcessing(true);

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-confirmation`,
        },
      });

      if (result.error) {
        // Handle specific error types
        switch (result.error.type) {
          case 'card_error':
          case 'validation_error':
            setValidationError(result.error.message);
            break;
          case 'invalid_request_error':
            setError('Invalid payment request. Please try again.');
            break;
          default:
            setError('An unexpected error occurred. Please try again.');
            console.error('Payment error:', result.error);
        }
      } else if (result.paymentIntent?.status === 'succeeded') {
        setPaymentComplete(true);
      }
    } catch (err) {
      console.error('Payment processing error:', err);
      setError('An unexpected error occurred. Please try again later.');
    } finally {
      setProcessing(false);
    }
  };

  if (paymentComplete) {
    return (
      <Alert severity="success" sx={{ mt: 2 }}>
        Payment successful! Redirecting...
      </Alert>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <PaymentElement 
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          }
        }}
      />
      
      {/* Show validation errors prominently */}
      {validationError && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {validationError}
        </Alert>
      )}
      
      {/* Show system/processing errors */}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!stripe || processing}
          fullWidth
          sx={{
            height: 48,
            position: 'relative'
          }}
        >
          {processing ? (
            <>
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-12px'
                }}
              />
              <span style={{ visibility: 'hidden' }}>Processing...</span>
            </>
          ) : (
            'Pay Now'
          )}
        </Button>
      </Box>
    </Box>
  );
};

// Main payment component remains mostly the same
const EstWizStep5Payment = () => {
  const { state, dispatch } = useEstimateRequest();
  const [error, setError] = useState(null);
  const clientSecretRef = useRef(null);
  const initializedRef = useRef(false);

  //const estimatePrice = 49.99;
  const estimatePrice = state.cost_net;
  const vatRate = 0.20;
  //const vatAmount = estimatePrice * vatRate;
  const vatAmount = state.cost_vat;
  const totalPrice = state.cost_gross;


  

  useEffect(() => {
    const initializePayment = async () => {
      if (initializedRef.current) return;
      initializedRef.current = true;

      try {
        setError(null);
        const response = await createPaymentIntent(state.id);

        if (response.clientSecret) {
          clientSecretRef.current = response.clientSecret;
          dispatch({
            type: 'UPDATE_FIELD',
            field: 'status',
            value: 'SUBMITTED',
          });
        } else {
          throw new Error('No client secret received from server');
        }
      } catch (error) {
        console.error('Error initializing payment:', error);
        setError(
          error.response?.data?.message ||
          'Failed to initialize payment. Please try again later.'
        );
      }
    };

    if (state.id) {
      initializePayment();
    }
  }, [state.id, dispatch]);

  if (!clientSecretRef.current && !error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const options = {
    clientSecret: clientSecretRef.current,
    ...stripeElementsOptions,
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <span>Estimate Fee:</span>
            <span>£{estimatePrice.toFixed(2)}</span>
          </Typography>
          <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <span>VAT ({(vatRate * 100).toFixed(0)}%):</span>
            <span>£{vatAmount.toFixed(2)}</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Total:</span>
            <span>£{totalPrice.toFixed(2)}</span>
          </Typography>
        </Box>
      </Paper>

      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Payment Details
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Elements stripe={stripePromise} options={options} key={clientSecretRef.current}>
          <PaymentForm setError={setError} error={error} />
        </Elements>
      </Paper>
    </Box>
  );
};

export default EstWizStep5Payment;