// src/components/wizards/estimateRequest/EstWizStep1VehicleInfoRates.js

import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import { useEstimateRequest } from './EstimateRequestContext';
import { lookupVehicle } from '../../../services/api';
import Grid from '@mui/material/Grid2';

const EstWizStep1VehicleInfoRates = forwardRef((props, ref) => {
  const { state, dispatch } = useEstimateRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [useProfileValues, setUseProfileValues] = useState(!state.custom_rates);
  const [platformFieldKey, setPlatformFieldKey] = useState(0);

  // Local validation errors
  const [validationErrors, setValidationErrors] = useState({});
  const [apiError, setApiError] = useState('');

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    validate() {
      const errors = {};

      // Validation logic using snake_case field names
      if (!state.vehicle_registration_number.trim()) {
        errors.vehicle_registration_number = 'Registration number is required';
      }
      if (!state.vehicle_make.trim()) {
        errors.vehicle_make = 'Make is required';
      }
      if (!state.vehicle_model.trim()) {
        errors.vehicle_model = 'Model is required';
      }
      const currentYear = new Date().getFullYear();
      if (!state.vehicle_year) {
        errors.vehicle_year = 'Year is required';
      } else if (isNaN(parseInt(state.vehicle_year, 10))) {
        errors.vehicle_year = 'Year must be a valid number';
      } else if (state.vehicle_year < 1900 || state.vehicle_year > currentYear) {
        errors.vehicle_year = `Year must be between 1900 and ${currentYear}`;
      }
      
      if (!state.vehicle_color.trim()) {
        errors.vehicle_color = 'Color is required';
      }
      if (!state.estimate_platform.trim()) {
        errors.estimate_platform = 'Estimate platform is required';
      }

      // Only validate rate fields if they are not using profile values
      if (!useProfileValues) {
        if (!state.labour_rate || state.labour_rate <= 0) {
          errors.labour_rate = 'Labour rate must be greater than 0';
        }
        if (
          state.parts_discount === null ||
          state.parts_discount < 0 ||
          state.parts_discount > 100
        ) {
          errors.parts_discount = 'Parts discount must be between 0 and 100';
        }
        if (
          state.paint_discount === null ||
          state.paint_discount < 0 ||
          state.paint_discount > 100
        ) {
          errors.paint_discount = 'Paint discount must be between 0 and 100';
        }
      }

      setValidationErrors(errors);

      const isValid = Object.keys(errors).length === 0;

      return { is_valid: isValid, errors };
    },
  }));

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'UPDATE_FIELD', field: name, value });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    setApiError(''); // Clear API error on input change

    // Handle platform selection change
    if (name === 'estimate_platform') {
      let platformAccountId = '';

 
      
      switch (value) {
        case 'GT Global':
          platformAccountId = state.gt_global_account_id || '';
          break;
        case 'Audatex':
          platformAccountId = state.audatex_account_id || '';
          break;
        default:
          platformAccountId = '';
      }

        // Force TextField re-render by updating key
      setPlatformFieldKey(prev => prev + 1);

      // Update platform_account_id based on selected platform
      dispatch({ 
        type: 'UPDATE_FIELD', 
        field: 'platform_account_id', 
        value: platformAccountId 
      });
    }


  };

  // Handle checkbox change for using profile values
  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setUseProfileValues(checked);

    // Update custom_rates in the state (inverse of useProfileValues)
    dispatch({
      type: 'UPDATE_FIELD',
      field: 'custom_rates',
      value: !checked,
    });

    if (checked) {
      // Reset rate fields to profile values when using profile values
      dispatch({
        type: 'UPDATE_FIELD',
        field: 'labour_rate',
        value: state.profile_labour_rate,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        field: 'parts_discount',
        value: state.profile_parts_discount,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        field: 'paint_discount',
        value: state.profile_paint_discount,
      });
    }

    // Clear validation errors for rate fields
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      labour_rate: '',
      parts_discount: '',
      paint_discount: '',
    }));
    setApiError('');
  };


  // Handle retail menu file checkbox change
  const handleRetailMenuCheckboxChange = (event) => {
    const checked = event.target.checked;
    dispatch({
      type: 'UPDATE_FIELD',
      field: 'attach_menu_file',
      value: checked,
    });
    dispatch({
      type: 'UPDATE_FIELD',
      field: 'retail_menu_file',
      value: checked ? state.profile_retail_menu_file : null,
    });    
  };


  // Sync useProfileValues when state.custom_rates changes
  useEffect(() => {
    setUseProfileValues(!state.custom_rates);
  }, [state.custom_rates]);

  
  

  
  
  
  // Handle vehicle lookup
  const handleLookup = async () => {

    //validate vehicle registration number
    if (!state.vehicle_registration_number.trim()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        vehicle_registration_number: 'Registration number is required',
      }));
      return;
    }

    setIsLoading(true);
    try {
      const response = await lookupVehicle(state.vehicle_registration_number);
      const vehicleInfo = response.data;

      // Update state with fetched vehicle information
      dispatch({ type: 'UPDATE_FIELD', field: 'vehicle_make', value: vehicleInfo.make || '' });
      dispatch({ type: 'UPDATE_FIELD', field: 'vehicle_model', value: vehicleInfo.model || '' });
      dispatch({
        type: 'UPDATE_FIELD',
        field: 'vehicle_year',
        value: vehicleInfo.year ? parseInt(vehicleInfo.year, 10) : '',
      });
      dispatch({ type: 'UPDATE_FIELD', field: 'vehicle_color', value: vehicleInfo.color || '' });
      dispatch({
        type: 'UPDATE_FIELD',
        field: 'raw_vehicle_data',
        value: JSON.stringify(vehicleInfo),
      });

      // Clear validation errors for vehicle fields
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        vehicle_registration_number: '',
        vehicle_make: '',
        vehicle_model: '',
        vehicle_year: '',
        vehicle_color: '',
      }));

    } catch (err) {
      console.error('Unable to fetch vehicle information:', err);
      setApiError('Unable to fetch vehicle information. Please enter details manually.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle file upload
  const handleRateFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch({ type: 'UPDATE_FIELD', field: 'retail_menu_file', value: file });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 12 }}>

          <Stack direction="row"
            spacing={2} >
            <TextField
              name="vehicle_registration_number"
              label="Registration Number"
              value={state.vehicle_registration_number || ''}
              onChange={handleChange}
              error={!!validationErrors.vehicle_registration_number}
              helperText={validationErrors.vehicle_registration_number}
            />

            <Button
              variant="outlined"
              onClick={handleLookup}
              disabled={isLoading}
              sx={{ height: '40px' }} // Set the height to 40px
            >
              {isLoading ? (
                <>
                  <CircularProgress size={20} color="inherit" />
                  &nbsp;Loading...
                </>
              ) : (
                'Lookup'
              )}
            </Button>

            {apiError && <Box fullWidth><Typography color="error">{apiError}&nbsp;</Typography></Box>}


          </Stack>
        </Grid>


        <Grid size={{ xs: 12, md: 6 }}>


          {/* Vehicle Make */}
          <TextField
            name="vehicle_make"
            fullWidth
            label="Make"
            value={state.vehicle_make || ''}
            onChange={handleChange}
            error={!!validationErrors.vehicle_make}
            helperText={validationErrors.vehicle_make}
          />

        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>

          {/* Vehicle Model */}
          <TextField
            name="vehicle_model"
            fullWidth
            label="Model"
            value={state.vehicle_model || ''}
            onChange={handleChange}
            error={!!validationErrors.vehicle_model}
            helperText={validationErrors.vehicle_model}
          />

        </Grid>


        <Grid size={{ xs: 12, md: 6 }}>

          {/* Vehicle Year */}
          <TextField
            name="vehicle_year"
            fullWidth
            label="Year"
            type="number"
            value={state.vehicle_year || ''}
            onChange={handleChange}
            error={!!validationErrors.vehicle_year}
            helperText={validationErrors.vehicle_year}
          />

        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>

          {/* Vehicle Color */}
          <TextField
            name="vehicle_color"
            fullWidth
            label="Color"
            value={state.vehicle_color || ''}
            onChange={handleChange}
            error={!!validationErrors.vehicle_color}
            helperText={validationErrors.vehicle_color}
          />

        </Grid>





        <Grid size={{ xs: 12, md: 6 }}>

          {/* Estimate Platform */}
          <FormControl fullWidth error={!!validationErrors.estimate_platform}>
            <InputLabel>Estimate Platform</InputLabel>
            <Select
              name="estimate_platform"
              value={state.estimate_platform || ''}
              onChange={handleChange}
              label="Estimate Platform"
            >
              <MenuItem value="GT Global">GT Global</MenuItem>
              <MenuItem value="Audatex">Audatex</MenuItem>
            </Select>
            {validationErrors.estimate_platform && (
              <Typography color="error">{validationErrors.estimate_platform}</Typography>
            )}
          </FormControl>


        </Grid>



        <Grid size={{ xs: 12, md: 6 }}>

          {/* GT Account Id or Audadex Network Id depending on plaform */}
          <TextField 
            fullWidth
            key={platformFieldKey}
            name="platform_account_id"
            value={state.platform_account_id || ''}
            onChange={handleChange}  // Add onChange handler
            label="Platform Account ID"
            error={!!validationErrors.platform_account_id}
            helperText={validationErrors.platform_account_id}
          />
        </Grid>




        {/* Use Profile Values Checkbox */}


        <Grid size={{ xs: 12, md: 12 }}>



          <FormControlLabel
            control={
              <Checkbox
                checked={useProfileValues}
                onChange={handleCheckboxChange}
                name="useProfileValues"
                color="primary"
              />
            }
            label="Use rates from your user account"
          />


        </Grid>


        <Grid size={{ xs: 12, md: 4 }}>

          {/* Labour Rate */}
          <TextField
            name="labour_rate"
            fullWidth
            label="Labour Rate"
            type="number"
            value={state.labour_rate || ''}
            onChange={handleChange}
            disabled={useProfileValues}
            error={!!validationErrors.labour_rate}
            helperText={validationErrors.labour_rate}
          />

        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>

          {/* Parts Discount */}
          <TextField
            name="parts_discount"
            fullWidth
            label="Parts Discount"
            type="number"
            value={state.parts_discount || ''}
            onChange={handleChange}
            disabled={useProfileValues}
            error={!!validationErrors.parts_discount}
            helperText={validationErrors.parts_discount}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          {/* Paint Discount */}
          <TextField
            name="paint_discount"
            fullWidth
            label="Paint Discount"
            type="number"
            value={state.paint_discount || ''}
            onChange={handleChange}
            disabled={useProfileValues}
            error={!!validationErrors.paint_discount}
            helperText={validationErrors.paint_discount}
          />
        </Grid>


        <FormControlLabel
          control={
            <Checkbox
              checked={state.attach_menu_file || false}
              onChange={handleRetailMenuCheckboxChange}
              name="attach_menu_file"
              color="primary"
            />
          }
          label="Attach your retail menu file"
        />

      </Grid></Box>
  );
});

export default EstWizStep1VehicleInfoRates;