import React from 'react';
import { Box, Paper, Typography, Divider, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { useSearchParams, useNavigate } from 'react-router-dom';

const EstimateComplete = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirectStatus = searchParams.get('redirect_status');
  const isSuccess = redirectStatus === 'succeeded';

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        p: 3
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: 500,
          width: '100%',
          p: 4,
          textAlign: 'center',
          mx: 'auto',  // Centers paper horizontally
          mt: 4        // Adds some top margin
        }}
      >
        <Box sx={{ mb: 3 }}>
          {isSuccess ? (
            <CheckCircleIcon sx={{ fontSize: 60, color: 'success.main' }} />
          ) : (
            <WarningIcon sx={{ fontSize: 60, color: 'warning.main' }} />
          )}
        </Box>

        <Typography variant="h4" gutterBottom>
          {isSuccess ? 'Payment Successful!' : 'Payment Status Update'}
        </Typography>

        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          {isSuccess ? 'Your estimate request is being processed' : 'We\'re checking on your payment status'}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="body1" sx={{ mb: 3 }}>
          {isSuccess 
            ? 'Thank you for your payment. Our team will begin working on your estimate right away. We will send you an email once your estimate is ready for review.'
            : 'We have not confirmed your payment status yet. Please check your dashboard or email for updates on your estimate request. If you believe this is an error, please contact our support team.'
          }
        </Typography>

        <Button
          variant="contained"
          size="large"
          onClick={() => navigate('/dashboard')}
          sx={{ mt: 3 }}
        >
          Go to Dashboard
        </Button>
      </Paper>
    </Box>
  );
};

export default EstimateComplete;