// src/stripeConfig.js

import { loadStripe } from '@stripe/stripe-js';
import theme from './theme';

export const stripePromise = loadStripe('pk_test_51PqDCIP5Corwpmq0yNGTBk1hMPpeORv7tMdFGzAlsDMzo5WWInJUGZ3XFi0YhUmwvuSiC5ZAapcKmJfhv2aDqkli006BQxGq75');

// Optionally, you can define your Stripe Elements options here if needed
export const stripeElementsOptions = {
    appearance: {
        theme: 'night',  // Use Stripe's dark theme as base
        variables: {
          colorPrimary: theme.palette.primary.main,
          colorBackground: theme.palette.background.paper,
          colorText: theme.palette.text.primary,
          colorDanger: theme.palette.error.main,
          colorTextSecondary: theme.palette.text.secondary,
          colorTextPlaceholder: theme.palette.text.disabled,
          fontFamily: theme.typography.fontFamily,
          fontSizeBase: '16px',
          spacingUnit: '4px',
          borderRadius: '4px',
        },
        rules: {
          '.Label': {
            color: theme.palette.text.secondary,
          },
          '.Input': {
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.divider,
          },
          '.Input:hover': {
            borderColor: theme.palette.primary.main,
          },
          '.Input--invalid': {
            borderColor: theme.palette.error.main,
          },
        },
      },
};